import React, { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    MenuItem,
    IconButton,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,

} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import TableMaker from "./TableMaker";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { url, makeReqBody } from "./store.js";
import { findIndex } from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import ExamTabMakerAnalysis from '../Exam/ExamTabMakerAnalysis';
import ChartMaker from './ChartMaker';
import StudentDetailSmartQBT from './StudentDetailSmartQBT';
import SubjectWiseDetail from '../DetailedAnalysis/SubjectWiseDetailed'



const useStyle = makeStyles((theme) => ({
    subContainer: {
        ...theme.typography.subContainer,
    },
    table: {
        backgroundColor: "transparent",
        minwidth: "30em",
        maxHeight: 440,
        tableLayout: "fixed",
        '& .MuiTableCell-root:first-child': {
            position: '-webkit-sticky',
            position: 'sticky',
            zIndex: "99",
            left: "0",
            backgroundColor: "#fafafa",
            maxWidth: '130px !important'
        },
        '&::-webkit-scrollbar': {
            width: '12px',
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    tableHead: {
        backgroundColor: theme.palette.common.white,
        width: "100%",
    },

}));

const styles = (theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.common.black,
    },
    DialogHeader: {
        backgroundColor: theme.palette.common.ltgrey,
    },
});


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    const theme = useTheme();
    return (
        <MuiDialogTitle
            disableTypography
            {...other}
            className={classes.DialogHeader}
        >
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ExamAnalysisMaker = (props) => {
    const [myMax, setMyMax] = useState(100);

    const getSetMax = (mymax) => {
        let temp = parseInt(mymax / 25);
        mymax = (temp + 1) * 25;
        setMyMax(mymax);
    };

    useEffect(() => {
        let mymax = 0;
        props.perData.datasets[0] &&
            props.perData.datasets[0].data.forEach((item) => {
                if (parseInt(item) > mymax) {
                    mymax = parseInt(item);
                }
            });

        console.log(props.perData);
        mymax > 100 ? getSetMax(mymax) : setMyMax(100);
    }, []);

    return (
        <React.Fragment>
            <Grid item container direction="row" justify="space-between">
                <Grid
                    item
                    container
                    direction="column"
                    sm
                    style={{ marginRight: "0.5em" }}
                >
                    <Grid item container>
                        {
                            <ChartMaker
                                dataset={props.perData}
                                width="100%"
                                maxsize={myMax}
                                yAxisLabel={
                                    (props.perData.type &&
                                        props.perData.type.subCategory &&
                                        props.perData.type.subCategory.toLowerCase() ===
                                        "highest marks") ||
                                        (props.perData.type &&
                                            props.perData.type.subCategory &&
                                            props.perData.type.subCategory.toLowerCase() ===
                                            "top 10 performers") ||
                                        (props.perData.type &&
                                            props.perData.type.subCategory &&
                                            props.perData.type.subCategory.toLowerCase() ===
                                            "bottom 10 performers") ||
                                        (props.perData.type &&
                                            props.perData.type.subCategory &&
                                            props.perData.type.subCategory.toLowerCase() ===
                                            "average marks") ||
                                        (props.perData.type &&
                                            props.perData.type.subCategory &&
                                            props.perData.type.subCategory.toLowerCase() ===
                                            "least marks")
                                        ? "Marks"
                                        : (props.perData.type &&
                                            props.perData.type.subCategory &&
                                            props.perData.type.subCategory.toLowerCase() ===
                                            "number of failures") ||
                                            (props.perData.type &&
                                                props.perData.type.subCategory &&
                                                props.perData.type.subCategory.toLowerCase() ===
                                                "number of 100%")
                                            ? "In Numbers"
                                            : undefined
                                }
                            />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};



const MyDataMaker = (props) => {
    const classes = useStyle();
    const theme = useTheme();
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(false);
    const [reRender, setRerender] = useState(false);
    const [showDetailAnalysis, setShowDetailAnalysis] = useState(false);
    const [showViewDetail, setShowViewDetail] = useState(false);
    const [studentData, setStudentData] = useState(null);




    useEffect(() => {
        setLoader(true);
        try {
            fetch(url, makeReqBody("SCHOOL_SMART_QBT_LIST_OF_STUDENT_BY_EXAM", {
                examTermId: props.examTermId,
                sectionId: parseInt(window.localStorage.sectionId),
                branchId: window.localStorage.branchId,
                subjectId: window.localStorage.teacherType === "Class" ? 0 : parseInt(window.localStorage.subjectId)
            }))
                .then((response) => response.json())
                .then((data) => {
                    let row = [];
                    data.respData[0].respMsg.result.stuMarksRes.length > 0
                        ? data.respData[0].respMsg.result.stuMarksRes.map((item, index) => {
                            row.push([
                                {
                                    title: item.studentName,
                                    type: "text"
                                },

                                { title: item.rollNo, type: "text" },
                                { title: `${item.marksObtained}/${item.totalMarks}`, type: "text", },
                                { title: item.rank, type: "text" },
                                { fullDetails: item, typeFeature: "smartExamAnalysis" },
                            ]);

                        })
                        : row.push([
                            { title: "No Data Available", type: "text" },
                            { title: " ", type: " " },
                            { title: " ", type: " " },
                            { title: " ", type: " " },
                        ]);
                    let classPerformnaceObj = data.respData[0].respMsg.result && data.respData[0].respMsg.result.classPerfObj;
                    Object.assign(classPerformnaceObj, { studentInstanceID: 0 });
                    console.log(classPerformnaceObj);

                    let classPerformanceData = [{
                        title: "Class Performance",
                        type: "text",
                    },
                    { title: "", type: "text", },
                    { title: `${classPerformnaceObj.average}/${classPerformnaceObj.totalMarks}`, type: "text" },
                    { title: "N/A", type: "text", },
                    { fullDetails: classPerformnaceObj, typeFeature: "smartExamAnalysis" },
                    ];
                    if (data.respData[0].respMsg.result && data.respData[0].respMsg.result.stuMarksRes.length > 0) {
                        row.splice(0, 0, classPerformanceData);
                    }

                    setRows(row);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                })

                .catch((data) => {
                    console.error(data);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                });
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }, [reRender]);


    const headers = [
        "Student Name",
        "Roll No.",
        "Marks",
        "Rank",
        "View Details",
    ];

    const SmartExamAnalysisDetail = (data) => {
        setStudentData(data);
        setShowDetailAnalysis(true);
        console.log("data", data)
    }
    const handleViewExamDetail = (data) => {
        setStudentData(data);
        setShowViewDetail(true);
    }





    return (
        <React.Fragment>
            {loader === true ? (
                <CircularProgress color="secondary" />
            ) : (
                <>
                    {props.index === 0 && window.localStorage.variantId === "7" &&
                        <Grid item >
                            <Typography style={{ paddingBottom: "1.1em" }} variant="body2">Note: All the analysis for mock exam is based on real time, it may vary based on number of students taking the exam at a given point of time.</Typography>
                        </Grid>}
                    {showDetailAnalysis &&
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setShowDetailAnalysis(false)}
                            style={{
                                color: "white",
                                marginBottom: "1em",
                            }}
                        >
                            Back To Student List
                        </Button>
                    }
                    {showViewDetail &&
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setShowViewDetail(false)}
                            style={{
                                color: "white",
                                marginBottom: "0.5em",
                                marginLeft: "1em",
                            }}
                        >
                            Back To Student List
                        </Button>
                    }

                    {(!showDetailAnalysis && !showViewDetail) &&
                        <>
                            <TableMaker
                                columns={headers}
                                rows={rows}
                                toReload={props.toReload}
                                setToReLoad={props.setToReLoad}
                                SmartExamAnalysisDetail={SmartExamAnalysisDetail}
                                handleViewExamDetail={handleViewExamDetail}
                                handleOpenReportCard={props.handleOpenReportCard}
                                termName={props.termName}
                            />
                        </>
                    }
                    {showDetailAnalysis &&
                        <SubjectWiseDetail
                            examTermId={props.examTermId}
                            examId={studentData && studentData.examId}
                            studentInstanceId={studentData && studentData.studentInstanceID}
                            render={showDetailAnalysis === true}
                        />
                    }
                    {showViewDetail &&
                        <StudentDetailSmartQBT
                            examTermId={props.examTermId}
                            examId={studentData && studentData.examId}
                            studentInstanceID={studentData && studentData.studentInstanceID}
                            render={showDetailAnalysis === true}
                        />
                    }

                </>
            )}
        </React.Fragment>
    );
};

export default function ExamAnalysisTeacherSmartQBT() {

    const classes = useStyle();
    const theme = useTheme();
    const [subjectManager, setSubjectManager] = useState([]);
    const [tabhead, setTabHead] = useState([]);
    const [loader, setLoader] = useState(true);
    const [uploadDialog, setUploadDialog] = useState(false);
    const [newData, setNewData] = useState({});
    const [myOptions, setMyOptions] = useState([]);
    const [myloader, setMyLoader] = useState(false);
    const [id, setId] = useState(0);
    const [reRender, setReRender] = useState(false);
    const [reportRows, setReportRows] = useState([]);
    const [consolidatedData, setConsolidatedData] = useState([]);
    const [openReportCard, setOpenReportCard] = useState(false);
    const [reportHeadings, setReportHeadings] = useState([]);
    const [scroll] = useState("paper");


    const month = {
        "01": "JAN",
        "02": "FEB",
        "03": "MAR",
        "04": "APR",
        "05": "MAY",
        "06": "JUN",
        "07": "JUL",
        "08": "AUG",
        "09": "SEP",
        10: "OCT",
        11: "NOV",
        12: "DEC",
    };

    const dateFromatFunc = (startDateTime, endDateTime) => {

        const sd = startDateTime.split(" ")[0];
        const st = startDateTime.split(" ")[1];
        const et = endDateTime.split(" ")[1];

        const sdSplit = sd.split("-");
        const sy = sdSplit[0];
        const sm = month[sdSplit[1]];
        const sday = sdSplit[2];

        const stSplit = st.split(":");
        const sh = stSplit[0];
        const smin = stSplit[1];

        let startAmPm = "am";
        if (parseInt(sh) >= 12) {
            startAmPm = "pm";
        }

        const etSplit = et.split(":");
        const eh = etSplit[0];
        const emin = etSplit[1];

        let endAmPm = "am";
        if (parseInt(sh) >= 12) {
            endAmPm = "pm";
        }

        let duration =
            sm +
            " " +
            sday +
            "," +
            " " +
            sy +
            "," +
            " " +
            sh +
            ":" +
            smin +
            " " +
            startAmPm +
            " " +
            "to" +
            " " +
            eh +
            ":" +
            emin +
            " " +
            endAmPm;

        return duration;
    };


    useEffect(() => {
        setLoader(true);
        const getQueryData = async () => {
            let termData = [];
            let subjectName = [];
            await fetch(url, makeReqBody("SCHOOL_GET_EXAM_TERMS_FOR_BRANCH", window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? {
                id: window.localStorage.branchId,
                sectionId: window.localStorage.sectionId
            } : {
                id: window.localStorage.branchId,
                standard: window.localStorage.className,
            }))
                .then((response) => response.json())
                .then((data) => {
                    data.respData[0].respMsg.result.map((item, index1) => {
                        subjectName.push(item.name);
                        termData.push(item);
                    });
                    setTabHead(subjectName);
                })
                .catch((data) => {
                    console.log("sorry something went wrong");
                });
            try {
                let AllLists = [];

                termData.map((item, index) => {
                    let temList = (
                        <MyDataMaker
                            termName={item.name}
                            dateFromatFunc={dateFromatFunc}
                            key={index}
                            // handleClassPerOpen={handleClassPerOpen}
                            examTermId={item.examTermId}
                            handleOpenReportCard={handleOpenReportCard}
                            index={index}
                        />
                    );

                    AllLists.push(temList);
                });
                setSubjectManager(AllLists);
            } catch (error) {
                console.error(error);
            }
        };
        getQueryData();


        getQueryData().catch((data) => console.error(data));
        setInterval(() => {
            setLoader(false);
        }, 500);
    }, [reRender]);


    // const handleClassPerOpen = (e, id) => {
    //     setId(id);
    //     try {
    //         fetch(url,
    //             makeReqBody("MDM_GET_LOOKUP_SUB_CATG", {
    //                 categoryName: "ClassPerformanceType",
    //                 flag: true,
    //             })
    //         )
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 setMyOptions(data.respData[0].respMsg.results);
    //                 setUploadDialog(true);
    //             })
    //             .catch((data) => {
    //                 console.error(data);
    //                 setUploadDialog(true);
    //             });
    //     } catch (error) {
    //         console.error(error);
    //         setUploadDialog(true);
    //     }
    // };


    const handleCloseUploadDialog = () => {
        setUploadDialog(false);
        setOpenReportCard(false)
    };

    // const handleChange = (e) => {
    //     setMyLoader(true);
    //     let myReqIndex = findIndex(
    //         myOptions,
    //         (o) => o.subCategoryId === e.target.value
    //     );
    //     let dataset = {
    //         perData: "",
    //         type: myOptions[myReqIndex],
    //         labels: [],
    //         datasets: [
    //             {
    //                 label: "Performance",
    //                 backgroundColor: theme.palette.secondary.main,
    //                 barThickness: "flex",
    //                 barPercentage: 0.5,
    //                 categoryPercentage: 0.3,
    //                 // maxBarThickness: 45,
    //                 borderColor: "#ff6d00",
    //                 borderSkipped: "left",
    //                 data: [],
    //             },
    //         ],
    //     };

    //     fetch(url,
    //         makeReqBody("SCHOOL_EXAM_ANLY_CLASS_PERF", {
    //             branchId: parseInt(window.localStorage.branchId),
    //             classPerfTypeId: parseInt(e.target.value),
    //             examTermId: id,
    //             sectionId: parseInt(window.localStorage.sectionId),
    //             secSubMappingId: parseInt(window.localStorage.secSylMappingId),
    //             subjectTeacher:
    //                 window.localStorage.teacherType == "Class" ? false : true,
    //         })
    //     )
    //         .then((response) => response.json())
    //         .then((data) => {
    //             data.respData[0].respMsg.result.map((item, index) => {
    //                 dataset.labels.push(item.key);
    //                 dataset.datasets[0].data.push(item.value.replace("%", ""));
    //             });

    //             setNewData(dataset);
    //             setMyLoader(false);
    //         });
    // };


    const handleOpenReportCard = (e, data) => {
        let stuData = data
        fetch(url, makeReqBody(data.studentInstanceID === 0 ? "SCHOOL_SMART_QBT_CLASS_PERF_REPORT_CARD_ANALYSIS" : "SCHOOL_SMART_QBT_EXAM_REPORT_CARD_ANALYSIS", {
            branchId: window.localStorage.branchId,
            examTermId: data.examTermId,
            sectionId: window.localStorage.sectionId,
            studentInstanceId: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? data.studentInstanceID : data.studentInstanceId,
            userId: window.localStorage.userId,
        })).then((response) => response.json())
            .then((data) => {
                let heading = ["Subjects"];
                let myData = [];
                let totalData = stuData.studentInstanceID === 0 ? [
                    [{ title: "Total Marks" }],
                    [{ title: "Percentage" }],
                ] : [
                    [{ title: "Total Marks" }],
                    [{ title: "Percentage" }],
                    [{ title: "Rank" }],
                ];
                let reportCardData = [];
                data.respData[0].respMsg.result &&
                    Object.keys(data.respData[0].respMsg.result).map((item, index) => {
                        heading.push(item);
                    });

                setReportHeadings(heading);

                Object.keys(data.respData[0].respMsg.result).map((item, index) => {
                    totalData[0].push({
                        title: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? `${data.respData[0].respMsg.result[item].marksObtained}/${data.respData[0].respMsg.result[item].totMarks}` : data.respData[0].respMsg.result[item].totMarks,
                    });
                    totalData[1].push({
                        title: data.respData[0].respMsg.result[item].percentage,
                    });
                    if (stuData.studentInstanceID !== 0) {
                        totalData[2].push({
                            title: data.respData[0].respMsg.result[item].overallRank,
                        });
                    }
                    let topush = true;
                    data.respData[0].respMsg.result[item].subjectWiseList.map((item, index) => {
                        let indexto = 0;
                        reportCardData.some((data, index) => {
                            let myindex = findIndex(
                                data,
                                (o) => o.title === item.subjectName
                            );
                            if (myindex >= 0) {
                                topush = false;
                                indexto = index;
                            } else {
                                topush = true;
                            }

                            return myindex >= 0;
                        });
                        if (topush) {
                            reportCardData.push([
                                { title: item.subjectName },
                                { title: item.subPerc === null ? "N/A" : item.subPerc, status: item.subResult },
                            ]);
                        } else {
                            if (reportCardData[indexto] && reportCardData[indexto].length < heading.length) {
                                reportCardData[indexto].push({
                                    title: item.subPerc === null ? "N/A" : item.subPerc,
                                    status: item.subResult,
                                });
                            }
                        }
                    }
                    );
                    myData = reportRows;


                });
                if (Object.keys(data.respData[0].respMsg.result).length !== 0) {
                    setReportRows(reportCardData);
                    setConsolidatedData(totalData);
                } else {
                    setReportRows([])
                    setConsolidatedData([])
                }
                console.log("report", totalData)
            });
        setOpenReportCard(true);
    };


    return (
        <>
            <Grid
                item
                container
                direction="column"
                spacing={1}
                className={classes.subContainer}
            >
                <Grid item>
                    {loader === false ? (
                        <ExamTabMakerAnalysis
                            tabs={tabhead}
                            data={subjectManager}
                            subjectBased={true}
                        />
                    ) : (
                        <CircularProgress color="secondary" />
                    )}
                </Grid>
            </Grid>

            <Dialog
                open={openReportCard}
                maxWidth={"md"}
                scroll={scroll}
                onClose={handleCloseUploadDialog}
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseUploadDialog}
                >
                    Report Card
                </DialogTitle>
                <DialogContent style={{ minWidth: "45em", minHeight: "30em" }}>
                    <TableContainer className={`${classes.table}`} >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    {reportHeadings.map((item, index) => (
                                        <TableCell
                                            key={item + index}
                                            style={{ cursor: "pointer", fontWeight: "bold" }}
                                        >
                                            <Grid container>
                                                <Grid item>{item}</Grid>
                                            </Grid>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportRows.map((item, index) => (
                                    <React.Fragment key={item + index}>
                                        <TableRow key={item.title + index}>
                                            {item.map((row, index) => (
                                                <TableCell
                                                    key={row + index}
                                                    style={{
                                                        maxWidth: "6.5em",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        borderBottom: "none",
                                                        fontWeight: index === 0 ? "bold" : undefined,
                                                        color: index !== 0 && row.status.toLowerCase() !== "pass"
                                                            ? "red" : "inherit"
                                                    }}
                                                >
                                                    {row.title}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                                {consolidatedData.map((item, index) => (
                                    <React.Fragment key={item + index}>
                                        <TableRow key={item.title + index}>
                                            {item.map((row, index) => (
                                                <TableCell
                                                    key={row + index}
                                                    style={{
                                                        maxWidth: "6.5em",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        fontWeight: index === 0 ? "bold" : undefined,
                                                    }}
                                                >
                                                    {row.title}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </>
    )
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import WorkSpaceHeader from "./Ui/WorkSpaceHeader.js";

import { Grid, Typography, Fade } from "@material-ui/core";

import { useRouteMatch } from "react-router-dom";

import ToDoCw from "./Ui/ToDoCw.js";
import ToDoHw from "./Ui/ToDoHw.js";
import ToDoRecord from "./Ui/ToDoRecord.js";
import ToDoAssignment from "./Ui/ToDoAssignment.js";
import ToDoObservation from "./Ui/ToDoObservation.js";
import ToDoProject from "./Ui/ToDoProject.js";
import { useLastLocation } from "react-router-last-location";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    ...theme.typography.mainContainer,
    height: "100% ",
    minHeight: "130vh",
  },
  headingItem: {
    ...theme.typography.tilesHeading,
    boxShadow: theme.shadows[5],
  },
}));

export default function ToDoTracker(props) {
  const classes = useStyle();
  let { path, url } = useRouteMatch();
  const lastLocation = useLastLocation();

  const Classwork = (
    <React.Fragment>

      <Grid item container direction="column">
        <WorkSpaceHeader title={window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? "DPP" : "CLASSWORK"} moduleId={13} />
        <ToDoCw />
      </Grid>
    </React.Fragment>
  );

  const homework = (
    <React.Fragment>

      <Grid item container direction="column">
        <WorkSpaceHeader title="HOMEWORK" moduleId={14} />
        <ToDoHw />
      </Grid>
    </React.Fragment>
  );

  const record = (
    <React.Fragment>

      <Grid item container direction="column">
        <WorkSpaceHeader title="RECORD" moduleId={15} />
        <ToDoRecord />
      </Grid>
    </React.Fragment>
  );

  const observation = (
    <React.Fragment>

      <Grid item container direction="column">
        <WorkSpaceHeader title="OBSERVATION" moduleId={16} />
        <ToDoObservation />
      </Grid>
    </React.Fragment>
  );

  const project = (
    <React.Fragment>

      <Grid item container direction="column">
        <WorkSpaceHeader title="PROJECT" moduleId={18} />
        <ToDoProject />
      </Grid>
    </React.Fragment>
  );

  const assignment = (
    <React.Fragment>

      <Grid item container direction="column">
        <WorkSpaceHeader title="ASSIGNMENT" moduleId={17} />
        <ToDoAssignment />
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        className={classes.mainContainer}
        style={{ width: `calc(100% - 12em)` }}
      >
        {window.location.pathname === "/ToDoTracker" ? (
          Classwork
        ) : window.location.pathname === "/ToDoTracker/Classwork" ? (
          Classwork
        ) : window.location.pathname === "/ToDoTracker/Homework" ? (
          homework
        ) : window.location.pathname === "/ToDoTracker/Record" ? (
          record
        ) : window.location.pathname === "/ToDoTracker/Assignment" ? (
          assignment
        ) : window.location.pathname === "/ToDoTracker/Observation" ? (
          observation
        ) : window.location.pathname === "/ToDoTracker/Project" ? (
          project
        ) : (
          <Typography variant="h1">To be designed</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
}

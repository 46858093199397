import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  Table,
  CircularProgress, Backdrop,
} from "@material-ui/core";
import ExamTabMakerAnalysis from "../Exam/ExamTabMakerAnalysis";
import ChartMaker from "./ChartMaker.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TrendSetter from "./TrendSetter";
import { useTheme } from "@material-ui/core/styles";
import { url, makeReqBody } from "./store.js";
import { groupBy } from "lodash";
import { findIndex } from "lodash";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import AreaChart from "./AreaChart";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
    maxHeight: 440,
    tableLayout: "fixed",
    '& .MuiTableCell-root:first-child': {
      position: '-webkit-sticky',
      position: 'sticky',
      zIndex: "99",
      left: "0",
      backgroundColor: "#fafafa",
      maxWidth: '130px !important'
    },
    '&::-webkit-scrollbar': {
      width: '12px',
      backgroundColor: '#F5F5F5'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
}));
const tabheadings = [
  "PRE-MID TERM",
  "POST-MID TERM",
  "POST-MIDTERM",
  "FINAL TERM",
];

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MyDataMaker = (props) => {
  const [dataset, setDataset] = useState({});
  const [trendDataset, setTrendDataset] = useState({});
  const [loader, setLoader] = useState(false);
  const [noData, setNodata] = useState(false);
  const theme = useTheme();
  const classes = useStyle();
  const [backLoader, setBackLoader] = useState(false);
  const [showReportCardBtn, setShowReportCardBtn] = useState(false);
  useEffect(() => {
    if (window.localStorage.boardId <= 6) {
      setLoader(true);

      try {
        let listdata = [];
        let newlistdata = [];
        let dataset = {
          perData: "",

          labels: [],
          datasets: [
            {
              label: "Class Performance",
              backgroundColor: "#e0e0e0",
              borderColor: "#f57c00",
              barThickness: "flex",
              barPercentage: 0.5,
              categoryPercentage: 0.3,

              // maxBarThickness: 45,
              borderSkipped: "left",
              data: [],
            },
            {
              label: "My Performance",
              backgroundColor: theme.palette.secondary.main,
              barThickness: "flex",
              barPercentage: 0.5,
              categoryPercentage: 0.3,
              // maxBarThickness: 45,
              borderColor: "#ff6d00",
              borderSkipped: "left",
              data: [],
            },
          ],
        };
        let trendData = {
          labels: [],
          datasets: [],
        };

        fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",
                busiCode: "SCHOOL_TREND_ANALYSIS_BY_STUDENT_INSTANCE_ID",
                busiParams: {
                  branchId: window.localStorage.branchId,
                  examTermId: props.examTermId,
                  sectionId: window.localStorage.sectionId,
                  studentInstanceId: window.localStorage.studentInstanceId,
                  userId: window.localStorage.userId,
                },
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: window.localStorage.sessionId,
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            data.respData[0].respMsg !== null &&
              Object.keys(data.respData[0].respMsg.result).map((item, index) => {
                trendData.labels.push(item);
              });

            data.respData[0].respMsg !== null &&
              Object.keys(data.respData[0].respMsg.result).map((item, index) => {
                let grouped = groupBy(
                  data.respData[0].respMsg.result[item],
                  (item) => item.subjectName
                );
                for (let x in grouped) {
                  let mydata = [];
                  let topush = false;
                  grouped[x].map((item, index) => {
                    let newindex = findIndex(
                      trendData.datasets,
                      (o) => o.label === x
                    );
                    if (newindex >= 0) {
                      // debugger;
                      trendData.datasets[newindex].data.push(
                        item.totPerc.replace("%", "")
                      );
                      topush = false;
                    } else {
                      mydata.push(item.totPerc.replace("%", ""));
                      topush = true;
                    }
                  });

                  if (topush) {
                    let mycolor = props.getRandomColor();
                    trendData.datasets.push({
                      label: x,
                      data: mydata,
                      lineTension: 0,
                      fill: false,
                      borderColor: mycolor,
                      pointBackgroundColor: mycolor,
                      pointRadius: 4,
                      borderWidth: 1.5,
                    });
                  }
                }
              });
          });

        fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",
                busiCode: "SCHOOL_GET_PERFORMANCE_BY_STUDENT_INSTANCE_ID",
                busiParams: {
                  branchId: window.localStorage.branchId,
                  examTermId: props.examTermId,
                  sectionId: window.localStorage.sectionId,
                  studentInstanceId: window.localStorage.studentInstanceId,
                  userId: window.localStorage.userId,
                  secSubMappingId: window.localStorage.teacherType === "Subject" ? window.localStorage.secSylMappingId : '',
                },
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: window.localStorage.sessionId,
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            let component;
            //console.log(data);
            if (data.respData[0].respMsg.result && data.respData[0].respMsg.result !== null) {
              setShowReportCardBtn(true);
              dataset.overallRank =
                data.respData[0].respMsg &&
                data.respData[0].respMsg.result.overallRank;
              data.respData[0].respMsg &&
                data.respData[0].respMsg.result.performanceBar &&
                data.respData[0].respMsg.result.performanceBar.map(
                  (item, index) => {
                    dataset.labels.push(item.subjectName);
                    dataset.datasets[1].data.push(
                      item.myperformance.replace("%", "")
                    );
                    dataset.datasets[0].data.push(
                      item.averagePerformance.replace("%", "")
                    );
                    // dataset.datasets[2].data.push(item.rank);
                  }
                );
              console.log("ffffff", data.respData[0].respMsg.result.performanceBar)
            } else {
              setInterval(() => {
                setLoader(false);
              }, 1000);
            }
            console.log("dataset", dataset)
            setTrendDataset(trendData);
            setDataset(dataset);
            setInterval(() => {
              setLoader(false);
            }, 1000);
          });
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    }
  }, []);


  useEffect(() => {
    if (window.localStorage.boardId > 6 && window.localStorage.boardId < 10) {
      setLoader(true);
      try {

        let isMyPerfNegative = false;
        let dataset = {
          perData: "",

          isNegative: false,
          labels: [],
          datasets: [{
            label: "Class Performance",
            backgroundColor: "#e0e0e0",
            borderColor: "#f57c00",
            barThickness: "flex",
            barPercentage: 0.5,
            categoryPercentage: 0.3,
            borderSkipped: "left",
            data: [],
          }, {
            label: "My Performance",
            backgroundColor: ["#ff7c44", "#084791", "#00b940", "#498bff", "#fbaa2b",],
            barThickness: "flex",
            barPercentage: 0.5,
            categoryPercentage: 0.3,
            borderColor: "#ff6d00",
            borderSkipped: "left",
            data: [],
          }],
        };

        fetch(url, makeReqBody("SCHOOL_SMART_QBT_EXAM_DETAILS_ANALYSIS", {
          branchId: window.localStorage.branchId,
          examTermId: props.examTermId,
          sectionId: window.localStorage.sectionId,
          studentInstanceId: window.localStorage.studentInstanceId,
          userId: window.localStorage.userId,
        })).then((res) => res.json())
          .then((data) => {
            if (data.respData[0].respMsg && data.respData[0].respMsg !== null) {
              setShowReportCardBtn(true);
              dataset.overallRank = data.respData[0].respMsg && data.respData[0].respMsg.result.overallRank;
              data.respData[0].respMsg && data.respData[0].respMsg.result.performanceBar &&
                data.respData[0].respMsg.result.performanceBar.map((item, index) => {
                  if (Math.floor(item.myperformance.replace("%", "")) >= 0 && Math.floor(item.averagePerformance.replace("%", "")) >= 0 && !dataset.isNegative) {
                    dataset.isNegative = false;
                  } else {
                    dataset.isNegative = true;
                  }
                  dataset.labels.push(item.subjectName);
                  dataset.datasets[1].data.push(
                    item.myperformance.replace("%", "")
                  );
                  dataset.datasets[0].data.push(
                    item.averagePerformance.replace("%", "")
                  );

                });
              console.log("isMyPerfNegative", dataset)
            }

            setDataset(dataset);
          });



        let trendData = {
          labels: [],
          datasets: [],
          isNegative: false,
        };

        fetch(url, makeReqBody("SCHOOL_SMART_QBT_EXAM_TREND_ANALYSIS", {
          branchId: window.localStorage.branchId,
          examTermId: props.examTermId,
          sectionId: window.localStorage.sectionId,
          studentInstanceId: window.localStorage.studentInstanceId,
          userId: window.localStorage.userId,
        })).then((response) => response.json())
          .then((data) => {
            data.respData[0].respMsg !== null &&
              Object.keys(data.respData[0].respMsg.result).map((item, index) => {
                trendData.labels.push(item);
              });

            data.respData[0].respMsg !== null &&
              Object.keys(data.respData[0].respMsg.result).map((item, index) => {
                let grouped = groupBy(
                  data.respData[0].respMsg.result[item],
                  (item) => item.subjectName
                );
                for (let x in grouped) {
                  let mydata = [];
                  let topush = false;
                  grouped[x].map((item, index) => {
                    let newindex = findIndex(
                      trendData.datasets,
                      (o) => o.label === x
                    );
                    if (newindex >= 0) {
                      trendData.datasets[newindex].data.push(
                        item.totPerc.replace("%", "")
                      );
                      topush = false;
                    } else {
                      mydata.push(item.totPerc.replace("%", ""));
                      topush = true;
                    }

                    if(item.totPerc.replace("%", "") >=0 && !trendData.isNegative){
                      trendData.isNegative = false
                    } else {
                      trendData.isNegative = true
                    }

                  });

                  if (topush) {
                    let mycolor = props.getRandomColor();
                    trendData.datasets.push({
                      label: x,
                      data: mydata,
                      lineTension: 0,
                      fill: false,
                      borderColor: mycolor,
                      pointBackgroundColor: mycolor,
                      pointRadius: 4,
                      borderWidth: 1.5,
                    });
                  }
                }
              });
            setTrendDataset(trendData);
            setInterval(() => {
              setLoader(false);
            }, 1000);
          });
      } catch (error) {
        console.log(error)
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    }
  }, [])

  const featuresData = JSON.parse(window.localStorage.getItem("features") || "[]");
  const feature = featuresData && featuresData.filter(item => item.featureId === 38).length > 0;

  const downloadReport = () => {
    try {
      setBackLoader(true);
      fetch(
        url,
        makeReqBody("SCHOOL_GET_EXAM_REPORT", {
          branchId: window.localStorage.branchId,
          examTermId: props.examTermId,
          module: feature ? "ExamDetailedAnalysis" : "ExamAnalysis",
          secSubMappingId: 0,
          sectionId: window.localStorage.sectionId,
          studentInstanceId: [parseInt(window.localStorage.studentInstanceId)],
        })
      )
        .then((response) => response.json())
        .then((data) => {
          setBackLoader(false);
          if (data.respData[0].respMsg.result && data.respData[0].respMsg.result.url !== null) {
            window.open(data.respData[0].respMsg.result.url);
          }
        })
        .catch((data) => {
          console.error(data);
          setBackLoader(false);
        });
    } catch (error) {
      console.error(error);
      setBackLoader(false);
    }
  }

  return (
    <React.Fragment>
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <ExamAnalysisMaker
          trendData={trendDataset}
          perData={dataset}
          handleOpenDialog={props.handleOpenDialog}
          downloadReport={downloadReport}
          showReportCardBtn={showReportCardBtn}
          index={props.index}
        />
      )}

      <Backdrop className={classes.backdrop} open={backLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ExamAnalysisMaker = (props) => {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <React.Fragment>
      {props.index === 0 && window.localStorage.variantId === "7" &&
        <Grid item >
          <Typography style={{ paddingBottom: "1.1em" }} variant="body2">Note: All the analysis for mock exam is based on real time, it may vary based on number of students taking the exam at a given point of time.</Typography>
        </Grid>}
      <Grid item container direction="row" justify="space-between">
        <Grid
          item
          container
          direction="column"
          sm
          style={{ marginRight: "0.5em" }}
        >
          <Grid
            item
            container
            spacing={4}
            alignItems="center"
            style={{ marginBottom: "0.1em" }}
          >
            {
              // <Grid item>
              //   <Typography
              //     variant="body1"
              //     style={{
              //       fontWeight: "bold",
              //       color: theme.palette.common.tabFont,
              //     }}
              //   >
              //     Overall Performance Score
              //   </Typography>
              // </Grid>
            }
            <Grid item>
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  color: theme.palette.common.tabFont,
                }}
              >
                Overall Rank : {props.perData.overallRank}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container style={{ height: '360px' , backgroundColor: "#fff"  }}>
            <ChartMaker
              dataset={props.perData}
              percentage={true}
              showBarVal={window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? true : false}
              negativeVal={window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? true : false}
              legends={window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? false : true}
            />
            {window.localStorage.studentInstanceId !== 0 &&
              <Typography variant="body2" style={{ fontWeight: "600", textAlign: "center", margin: "auto" }}><span style={{ paddingRight: "10px" }}>Bar1: Class Performance</span> <span>Bar2: My Performance</span></Typography>
            }
          </Grid>
        </Grid>
        <Grid item container direction="column" sm>
          <Grid
            item
            container
            spacing={4}
            alignItems="center"
            justify="space-between"
            style={{ marginBottom: "0.1em" }}
          >
            <Grid item>
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  color: theme.palette.common.tabFont,
                }}
              >
                Trend Analysis
              </Typography>
            </Grid>

            {props.showReportCardBtn === true && <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography
                color="secondary"
                style={{
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                onClick={(e) => props.handleOpenDialog(e)}
              >
                Report Card
              </Typography>

              {window.localStorage.boardId <= 6 &&
                <Typography
                  color="secondary"
                  component="a"
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    marginLeft: '20px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={(e) => props.downloadReport(e)}
                >
                  <GetAppOutlinedIcon color="secondary" size="20" /> Download Report
                </Typography>}


            </Grid>}
          </Grid>
          <Grid item container style={{ height: '360px' }}>
            <AreaChart
              width="100%"
              fullWidth
              data={props.trendData}
              percentage={true}
              // negativeVal={window.localStorage.boardId > 6 ? true : false}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default function ExamAnalysis() {
  const classes = useStyle();
  const theme = useTheme();
  const [subjectManager, setSubjectManager] = useState([]);
  const [tabhead, setTabHead] = useState([]);
  const [loader, setLoader] = useState(true);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [maxWidth] = useState("sm");
  const [scroll] = useState("paper");
  const [reportHeadings, setReportHeadings] = useState([]);
  const [reportRows, setReportRows] = useState([]);
  const [consolidatedData, setConsolidatedData] = useState([]);

  function getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const dataset = {
    labels: ["Science", "Hindi", "Maths", "English", "Social Science"],
    datasets: [
      {
        label: "Average Performance",
        backgroundColor: "#e0e0e0",
        borderColor: "#f57c00",
        barThickness: "flex",
        barPercentage: 0.8,
        categoryPercentage: 0.6,

        // maxBarThickness: 45,
        borderSkipped: "left",
        data: [70, 65, 75, 70, 75],
      },
      {
        label: "My Performance",
        backgroundColor: theme.palette.secondary.main,
        barThickness: "flex",
        barPercentage: 0.8,
        categoryPercentage: 0.6,
        // maxBarThickness: 45,
        borderColor: "#ff6d00",
        borderSkipped: "left",
        data: [75, 70, 70, 85, 70],
      },
    ],
  };

  useEffect(() => {
    setLoader(true);
    let AllList = [];
    const getQueryData = async () => {
      try {
        await fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            requestList: [
              {
                isEncrypt: false,
                transactionId: "0000001438241244",
                busiCode: "SCHOOL_GET_EXAM_TERMS_FOR_BRANCH",
                busiParams: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? {
                  id: window.localStorage.branchId,
                  sectionId: window.localStorage.sectionId
                } : {
                  id: window.localStorage.branchId,
                  standard: window.localStorage.className,
                },
              },
            ],
            pubInfo: {
              appId: "appId",
              sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
              version: "21",
              timestamp: "20180314175419",
              lang: "en_US",
              userId: "1000016614",
              serviceId: "7021150585",
              circleId: "MU",
              ncsroute: "WE015",
              customerId: "1000016614",
              osType: "windows",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            let tabheadings = [];

            // moment(item.date, "YYYY-MM-DD").toDate(),
            if (data.respData[0].respMsg.result.length > 0) {
              try {
                tabheadings = data.respData[0].respMsg.result.map(
                  (a) => a.name
                );
                data.respData[0].respMsg.result &&
                  data.respData[0].respMsg.result.map((item, index1) => {
                    let component = (
                      <MyDataMaker
                        key={index1}
                        examTermId={item.examTermId}
                        getRandomColor={getRandomColor}
                        handleOpenDialog={() => handleOpenDialog(item.examTermId)}
                        index={index1}
                      />
                    );

                    AllList.push(component);
                  });
                setSubjectManager(AllList);
                setTabHead(tabheadings);
              } catch (error) {
                console.error(error);
                setTabHead(["Network Error"]);
              }
            } else {
              setTabHead(["Not Assigned to a group"]);
            }
          })
          .catch((data) => {
            console.error(data);
          });
        // console.log(AllList);

        //setDiscussionForum(AllList);
      } catch (error) {
        console.error(error);
      }
    };

    getQueryData().catch((data) => console.error(data));
    setInterval(() => {
      setLoader(false);
    }, 500);
  }, []);

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  const handleOpenDialog = (id) => {
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        requestList: [
          {
            isEncrypt: false,
            transactionId: "0000001438241244",
            busiCode: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? "SCHOOL_SMART_QBT_EXAM_REPORT_CARD_ANALYSIS" : "SCHOOL_REPORT_CARD_BY_STUDENT_INSTANCE_ID",
            busiParams: {
              branchId: window.localStorage.branchId,
              examTermId: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? id : 0,
              sectionId: window.localStorage.sectionId,
              studentInstanceId: window.localStorage.studentInstanceId,
              userId: window.localStorage.userId,
            },
          },
        ],
        pubInfo: {
          appId: "appId",
          sessionId: "a89034ac-2a77-43c4-b70f-7886c62cb93f",
          version: "21",
          timestamp: "20180314175419",
          lang: "en_US",
          userId: "1000016614",
          serviceId: "7021150585",
          circleId: "MU",
          ncsroute: "WE015",
          customerId: "1000016614",
          osType: "windows",
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let heading = ["Subjects"];
        let myData = [];
        let totalData = [
          [{ title: "Total Marks" }],
          [{ title: "Percentage" }],
          [{ title: "Rank" }],
        ];
        let reportCardData = [];
        data.respData[0].respMsg.result &&
          Object.keys(data.respData[0].respMsg.result).map((item, index) => {
            heading.push(item);
          });

        setReportHeadings(heading);

        Object.keys(data.respData[0].respMsg.result).map((item, index) => {
          // let grouped = groupBy(
          //   data.respData[0].respMsg.result[item].subjectWiseList,
          //   (myitem) => myitem.subjectName
          // );
          totalData[0].push({
            title: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? `${data.respData[0].respMsg.result[item].marksObtained}/${data.respData[0].respMsg.result[item].totMarks}` : data.respData[0].respMsg.result[item].totMarks,
          });
          totalData[1].push({
            title: data.respData[0].respMsg.result[item].percentage,
          });
          totalData[2].push({
            title: data.respData[0].respMsg.result[item].overallRank,
          });
          let topush = true;

          data.respData[0].respMsg.result[item].subjectWiseList.map(
            (item, index) => {
              let indexto = 0;
              reportCardData.some((data, index) => {
                let myindex = findIndex(
                  data,
                  (o) => o.title === item.subjectName
                );
                if (myindex >= 0) {
                  topush = false;
                  indexto = index;
                } else {
                  topush = true;
                }

                return myindex >= 0;
              });
              if (window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ) {
                if (topush) {
                  reportCardData.push([
                    { title: item.subjectName },
                    { title: item.subPerc === null ? "NA" : item.subPerc, status: item.subResult },
                  ]);
                } else {
                  if (reportCardData[indexto] && reportCardData[indexto].length < heading.length) {
                    reportCardData[indexto].push({
                      title: item.subPerc === null ? "NA" : item.subPerc,
                      status: item.subResult,
                    });
                  }
                }
              } else {
                if (topush) {
                  reportCardData.push([
                    { title: item.subjectName },
                    { title: item.marksObtained, status: item.subResult },
                  ]);
                } else {
                  if (reportCardData[indexto] && reportCardData[indexto].length < heading.length) {
                    reportCardData[indexto].push({
                      title: item.marksObtained,
                      status: item.subResult,
                    });
                  }
                }
              }
            }
          );

          //console.log(grouped);
          // for (let x in grouped) {
          //   let mydata = [];
          //   grouped[x].map((item, index) => {
          //     mydata.push(item.totPerc.replace("%", ""));
          //   });

          //   console.log(grouped);
          // }
          myData = reportRows;

          setConsolidatedData(totalData);

        });
        setReportRows(reportCardData);
      });
    setUploadDialog(true);
  };

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <ExamTabMakerAnalysis
              tabs={tabhead}
              data={subjectManager}
              subjectBased={true}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>

      <Dialog
        open={uploadDialog}
        maxWidth={maxWidth}
        scroll={scroll}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Report Card
        </DialogTitle>
        <DialogContent style={{ minWidth: "45em", minHeight: "30em" }}>
          <TableContainer className={`${classes.table}`} >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {reportHeadings.map((item, index) => (
                    <TableCell
                      key={item + index}
                      style={{ cursor: "pointer", fontWeight: "bold" }}
                    >
                      <Grid container>
                        <Grid item>{item}</Grid>
                        {
                          // <Grid item>
                          //   {item === columnToSort ? (
                          //     sortDirection === "asc" ? (
                          //       <ArrowDropUpIcon
                          //         size="small"
                          //         style={{ marginLeft: "0.75em" }}
                          //       />
                          //     ) : (
                          //       <ArrowDropDownIcon
                          //         size="small"
                          //         style={{ marginLeft: "0.75em" }}
                          //       />
                          //     )
                          //   ) : (
                          //     <SortIcon
                          //       size="small"
                          //       style={{ marginLeft: "0.75em" }}
                          //     />
                          //   )}
                          // </Grid>
                        }
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportRows.map((item, index) => (
                  <React.Fragment key={item + index}>
                    <TableRow key={item.title + index}>
                      {item.map((row, index) => (
                        <TableCell
                          key={row + index}
                          style={{
                            maxWidth: "6.5em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            borderBottom: "none",
                            fontWeight: index === 0 ? "bold" : undefined,
                            color:
                              index !== 0
                                ? row.status.toLowerCase() !== "pass"
                                  ? "red"
                                  : "inherit"
                                : "inherit",
                          }}
                        >
                          {row.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                ))}
                {consolidatedData.map((item, index) => (
                  <React.Fragment key={item + index}>
                    <TableRow key={item.title + index}>
                      {item.map((row, index) => (
                        <TableCell
                          key={row + index}
                          style={{
                            maxWidth: "6.5em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontWeight: index === 0 ? "bold" : undefined,
                          }}
                        >
                          {row.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

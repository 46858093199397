import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  AppBar,
  Toolbar,
  Switch,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell, Tooltip

} from "@material-ui/core";
import moment from "moment";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { url, makeReqBody } from "./store.js";
import DisplayPdf from "./DisplayPdf";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },

  table: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? "#616161"
        : "transparent",
    minwidth: "30em",
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "0.85rem",
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.ltgrey
        : theme.palette.common.white,
    width: "100%",
  },
  toolBar: {
    ...theme.mixins.toolbar,
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },
  myDropZone: {
    color: "black !important",
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
    "& .MuiTypography-h6": {
        color: "black !important",
    },

  },

  videoAppBar: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },
  titlebar: {
    //  height: "6px",
    backgroundColor: theme.palette.common.ltgrey,
  },
  pdfScroll: {
    overflowY: "auto",

    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',
    }
  },
  customDropdown: {
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(14px, 14px) scale(1)',
      pointerEvents: 'none',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(12px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 12px',
    },
    '& .Mui-disabled': {
      background: '#eee',
    },
  },
  questionMapping: {
    // "& .MuiDialogContent-root" : {
    "& ::-webkit-scrollbar": {
      width: '7px!important',
      height: '5px',
    },
    "& ::-webkit-scrollbar-track:": {
      background: "#00256f",
    },
    
    "& ::-webkit-scrollbar-thumb": {
      background: "#f4ce2c",
    },
    
    "& ::-moz-scrollbar": {
      width: '10px',
      scrollbarWidth: 'thin',
    },
    
    "& ::-moz-scrollbar-track":{
      background: '#00256f',
    },
    
    "& ::-moz-scrollbar-thumb": {
      background: "#f4ce2c",
    },
  // },
},
iconButton:{
  padding: '6px',
},
customTooltip: {
// I used the rgba color for the standard "secondary" color
fontSize: "0.85rem",
},
}));
const tabheadings = [
  "CW",
  "HW",
  "ASSIGNMENT",
  "PROJECT",
  "OBSERVATION",
  "RECORD",
];

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  dropZone: {
    color: "black !important",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MyDataMaker = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [answerDropzone, setAnswerDropzone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [eExamId, setEexamId] = useState("");
  const [answerKey, setAnswerKey] = useState({
    url: "",
    fileName: "",
    fileType: "",
  });
  const [open, setOpen] = React.useState(false);
  const [hrefData, setHrefData] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [displayFullscreen, setDisplayFullscreen] = React.useState(false);
  const [uploadAnswerKey, setUploadAnswerKey] = useState(false);
  const [reRender, setRerender] = useState(false);

  useEffect(() => {
    setLoader(true);

    try {
      fetch(url, makeReqBody("SCHOOL_GET_EXAM_FOR_TEACHER", {
        examTermId: props.examTermId,
        secSubMappingId:
          window.localStorage.teacherType == "Class"
            ? 0
            : window.localStorage.secSylMappingId,
        sectionId: window.localStorage.sectionId,
        examType: props.examType
      })
      ).then((response) => response.json())
        .then((data) => {
          let row = [];
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
              window.localStorage.teacherType == "Class"
                ? row.push([
                  { title: item.subjectName, type: "text" },
                  {
                    title: moment(item.examDate)
                      .format("MMM DD, YYYY, HH:mm")
                      .toString(),
                    type: "text",
                  },
                  {
                    title: item.totalMarks,
                    type: "text",
                  },
                  {
                    title: item.passingMarks,
                    type: "text",
                  },
                  {
                    title: item.fileName,
                    type: item.fileType,
                    href: item.attachment,
                  },
                ])
                : row.push([
                  { title: item.subjectName, type: "text" },
                  {
                    title: moment(item.examDate)
                      .format("MMM DD, YYYY, HH:mm")
                      .toString(),
                    type: "text",
                  },
                  {
                    title: item.totalMarks,
                    type: "text",
                  },
                  {
                    title: item.passingMarks,
                    type: "text",
                  },
                  {
                    title: item.fileName,
                    type: item.fileType,
                    href: item.attachment,
                  },
                  {
                    title: "",
                    type: "verifyExam",
                    fullDetails: item,
                  },
                ]);
            })
            : row.push([
              { title: "No Data Available", type: "text" },
              { title: " ", type: " " },
              {
                title: " ",
                type: "text",
              },
              { title: " ", type: " " },
              {
                title: " ",
                type: "text",
              },
              { title: " ", type: " " },
            ]);

          if (data.respData[0].respMsg.result === null) {
            setUploadAnswerKey("false");
          } else {
            setUploadAnswerKey("true");
          }

          setEexamId(data.respData[0].respMsg.result[0].eExamId);
          setAnswerKey({
            url: data.respData[0].respMsg.result[0].uploadAnswerDetail,
            fileName: data.respData[0].respMsg.result[0].uploadAnswerFileName,
            fileType: data.respData[0].respMsg.result[0].uploadAnswerFileType,
          });


          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }



  }, [reRender]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSaveExamKey = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".")[0];
    let myFileType = files[0].type.split("/")[1];
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPLOAD_EXAM_ANSWERS",
              busiParams: {
                eExamId: eExamId,
                loginUserId: window.localStorage.userId,
                url: newattachment,
                fileName: myFileName,
                fileType: myFileType,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          setRerender(!reRender);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }


    setAnswerDropzone(false);
  }

  const handlePdfOpen = (e, href) => {
    console.log(href);
    setOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const handlepdfClose = (event) => {
    setOpen(false);
    window.localStorage.removeItem("idleDisable");
  };
  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleImageOpen = (e, href) => {
    setImageOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const openDropzone = () => {
    if (rows.length <= 0) {
      setMessage("Please upload an exam first.");
      setStatus("error")
      setSnack(true);
    } else {
      setAnswerDropzone(true)
    }
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        onClick={(e) => {
          props.handleOpenUploadDialog(e, props.examTermId);
        }}
        style={{ color: "white", marginBottom: "0.5em" }}
      >
        Upload e Exam
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={(e) => {
          props.openQuestionMapping(e, props.examTermId);
        }}
        style={{ color: "white", marginLeft: "1em", marginTop: "-0.5em" }}
      >
        Question Mapping
      </Button>
      {window.localStorage.teacherType && window.localStorage.teacherType === "Subject" &&
        <>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white", marginLeft: "1em", marginTop: "-0.5em" }}
            onClick={openDropzone}
          >
            Upload Answer
      </Button>
          <span
            onClick={
              answerKey.fileType === "pdf"
                ? (e) => handlePdfOpen(e, answerKey.url)
                : answerKey.fileType === "png" ||
                  answerKey.fileType === "jpg" ||
                  answerKey.fileType === "jpeg"
                  ? (e) => handleImageOpen(e, answerKey.url)
                  : null
            }
            style={{
              maxWidth: "10em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: theme.palette.common.blue,
              marginLeft: "1em",
              cursor: "pointer",
            }}>{answerKey.fileName}</span>
        </>
      }
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <TableMaker
          columns={props.headers}
          rows={rows}
          toReload={props.toReload}
          setToReLoad={props.setToReLoad}
        />
      )}

      {/* Answer Key */}
      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        filesLimit={1}
        maxFileSize={6000000}
        open={answerDropzone}
        dialogProps={{ className: classes.myDropZone }}
        onClose={() => setAnswerDropzone(false)}
        onSave={(files) => handleSaveExamKey(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        acceptedFiles={["image/*", "application/pdf"]}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <Dialog
        fullScreen={displayFullscreen}
        open={open}
        onClose={handlepdfClose}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlepdfClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}></Typography>
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: theme.palette.common.tabFont }}>
              Toggle View
            </Typography>
            <Tooltip
            title="Download Pdf"
            classes={{
              tooltip: classes.customTooltip,
            }}>
              <IconButton className={classes.iconButton} onClick={()=> window.open(hrefData)}>
                <GetAppOutlinedIcon color="primary"/>
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <Grid container direction="column" alignItems="center" className={`${classes.pdfScroll} customPdfScroll`}>
          <Grid item>
            <DisplayPdf data={hrefData} displayFullscreen={displayFullscreen} />
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={imageOpen}
        onClose={handleImageClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleImageClose}
        ></DialogTitle>
        <DialogContent>
          <img
            src={hrefData}
            alt="viewer"
            style={{ height: "40em", width: "40em" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleImageClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default function RegularExamTeacher(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [isReady, setIsReady] = useState(false);
  const [myTableData, setMyTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [chapter, setChapter] = useState([]);
  const [section, setSection] = useState([]);
  const [chapterSelected, setChapterSelected] = useState({});
  const [materialAdded, setMaterialAdded] = useState(false);
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
  });
  const [examDate, setExamDate] = useState(null);

  const [resourceId, setResourceId] = useState(0);
  const [eExam, setEExam] = useState([]);
  const [tabHeadings, setTabHeadings] = useState([]);
  const [marks, setMarks] = useState({
    passingMarks: 0,
    maxMarks: 0,
  });
  const [subjectSelected, setSubjectSelected] = useState({
    className: "",
    noOfPeriod: 0,
    secSubSylMappingId: 0,
    sectionId: 0,
    sectionNo: "",
    subjectId: 0,
    subjectName: "",
  });

  const [subjects, setSubjects] = useState(
    JSON.parse(window.localStorage.users || "[]")
  );
  const [myLoader, setMyLoader] = useState(false);

  const [questionMapping, setQuestionMapping] = useState(false);

  const [questionMappingData, setQuestionMappingData] = useState([
    {
      "chapterId": null,
      "chapterSectionId":[],
      "chapterSource": "",
      "identifierId": "",
      "totalMarks": "",
    }
  ]);

  const month = {
    "01": "JAN",
    "02": "FEB",
    "03": "MAR",
    "04": "APR",
    "05": "MAY",
    "06": "JUN",
    "07": "JUL",
    "08": "AUG",
    "09": "SEP",
    10: "OCT",
    11: "NOV",
    12: "DEC",
  };

  const dateFromatFunc = (startDateTime, endDateTime) => {
    console.log("inside date func");

    const sd = startDateTime.split(" ")[0];
    const st = startDateTime.split(" ")[1];
    const et = endDateTime.split(" ")[1];

    const sdSplit = sd.split("-");
    const sy = sdSplit[0];
    const sm = month[sdSplit[1]];
    const sday = sdSplit[2];

    const stSplit = st.split(":");
    const sh = stSplit[0];
    const smin = stSplit[1];

    let startAmPm = "am";
    if (parseInt(sh) >= 12) {
      startAmPm = "pm";
    }

    const etSplit = et.split(":");
    const eh = etSplit[0];
    const emin = etSplit[1];

    let endAmPm = "am";
    if (parseInt(sh) >= 12) {
      endAmPm = "pm";
    }

    let duration =
      sm +
      " " +
      sday +
      "," +
      " " +
      sy +
      "," +
      " " +
      sh +
      ":" +
      smin +
      " " +
      startAmPm +
      " " +
      "to" +
      " " +
      eh +
      ":" +
      emin +
      " " +
      endAmPm;

    return duration;
  };

  const headers =
    window.localStorage.teacherType == "Class"
      ? [
        "Subject",
        "Schedule",
        "Max Marks",
        "Passing Marks",
        "Question Paper",
      ]
      : [
        "Subject",
        "Schedule",
        "Max Marks",
        "Passing Marks",
        "Question Paper",
        "Action",
      ];

  useEffect(() => {
    try {
      fetch(
        url,
        makeReqBody("SCHOOL_GET_ALL_CHAPTER_SECTION", {
          admin: false,
          sectionId: window.localStorage.sectionId,
          subjectId: window.localStorage.subjectId,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mychapters = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mychapters.push(item);
            });
          setChapter(mychapters);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (data) {
      console.error(data);
    }
  }, []);

  useEffect(() => {
    setLoader(true);
    const getData = async () => {
      let termData = [];
      let subjectName = [];
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_EXAM_TERMS_FOR_BRANCH",
              busiParams: {
                id: window.localStorage.branchId,
                standard: window.localStorage.className,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.map((item, index1) => {
            subjectName.push(item.name);
            termData.push(item);
          });

          setTabHeadings(subjectName);
        })
        .catch((data) => {
          console.log("sorry something went wrong");
        });
      try {
        let AllLists = [];

        termData.map((item, index) => {
          let temList = (
            <MyDataMaker
              key={index}
              headers={headers}
              handleOpenUploadDialog={handleOpenUploadDialog}
              examTermId={item.examTermId}
              materialAdded={materialAdded}
              dateFromatFunc={dateFromatFunc}
              examType={props.examType}
              openQuestionMapping={openQuestionMapping}
            />
          );

          AllLists.push(temList);
        });
        console.log(AllLists);
        setEExam(AllLists);
      } catch (error) {
        console.error(error);
      }
    };
    getData();

    setInterval(() => {
      setLoader(false);
    }, 500);
  }, [materialAdded]);

  const handleOpenUploadDialog = async (e, id) => {
    console.log(id);
    setResourceId(id);

    setUploadDialog(true);
  };

  const openQuestionMapping = async (e, id) => {
    setQuestionMapping(true)
  }

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleSaveMaterial = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".")[0];
    let myFileType = files[0].type.split("/")[1];

    setMyAttachment({
      ...myAttachment,
      attachment: newattachment,
      fileName: myFileName,
      fileType: myFileType,
    });
    setMaterialName(myFileName);
    setDropZone(false);
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  const handleUploadSubmit = () => {
    console.log(resourceId);
    setMyLoader(true);

    try {
      fetch(
        url,
        makeReqBody("SCHOOL_ADD_EXAM", {
          attachment: myAttachment.attachment,
          branchId: parseInt(window.localStorage.branchId),
          description: "string",
          employeeID: parseInt(window.localStorage.employeeId),
          examTermId: resourceId,
          fileName: myAttachment.fileName,
          fileType: myAttachment.fileType,
          loginUserId: parseInt(window.localStorage.userId),
          passingMarks: parseInt(marks.passingMarks),
          secSubMappingId: subjectSelected.secSubSylMappingId,
          sectionId: parseInt(window.localStorage.sectionId),
          subjectId: subjectSelected.subjectId,
          subjectName: subjectSelected.subjectName,
          target: "internal",
          totalMarks: parseInt(marks.maxMarks),
          url: "",
          examType: props.examType,
          examDate: moment(examDate).format("YYYY-MM-DD HH:mm:ss"),
        })
      )
        .then((response) => response.json())
        .then((data) => {
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          setMyLoader(false);
          setMaterialAdded(!materialAdded);
          setMaterialName("");
          setExamDate();
          setMarks({
            maxMarks: "",
            passingMarks: "",
          });
          setUploadDialog(false);
        })
        .catch((data) => {
          console.error(data);
          setUploadDialog(false);
        });
    } catch (data) {
      console.error(data);
      setUploadDialog(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const addQuestionappingRow = () => {
   let data = [...questionMappingData];
   data.push({
      "chapterId": {},
      "chapterSectionId": {},
      "chapterSource": "",
      "identifierId": "",
      "totalMarks": "",
   });
   setQuestionMappingData(data);
  }

  const addChapterId = (index, e) => {
    let qmappingData = [...questionMappingData];

    qmappingData[index].chapterId = e.target.value;
    // questionMappingData[index].chapterSectionId = e.target.value.data;
    try {
      fetch(
        url,
        makeReqBody("SCHOOL_GET_COMPREHENSIVE_GUIDE_BY_CHAPTERID", {
          chapterId: e.target.value.chapterId,
          branchId: window.localStorage.branchId,
          source: e.target.value.source,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mychapters = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mychapters.push(item);
            });
          qmappingData[index].chapterSectionId =  mychapters;
          setQuestionMappingData(qmappingData);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (data) {
      console.error(data);
    }
  }

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker tabs={tabHeadings} data={eExam} subjectBased={true} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>


      <Dialog
        open={uploadDialog}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
        className={classes.dialogContainer}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Upload Exam
        </DialogTitle>
        <DialogContent
          style={{ scrollbarWidth: "none" }}
        >
          <Grid
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <TextField
                select
                variant="outlined"
                fullWidth
                label="Subject Name *"
                name="SubjectName"
                onChange={(e) => {
                  setSubjectSelected(e.target.value);
                  console.log(e.target.value);
                }}
              >
                {subjects && subjects.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item.subjectName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item container spacing={1} alignItems="center">
              <Grid item sm>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Max Marks*"
                  inputProps={{ type: "number", min: "0" }}
                  value={marks.maxMarks}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setMarks({ ...marks, maxMarks: e.target.value });
                    } else {
                      setStatus("Warning");
                      setMessage("Negative Value Not Allowed");
                      setSnack(true);
                    }
                  }}
                />
              </Grid>
              <Grid item sm>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Passing Marks *"
                  value={marks.passingMarks}
                  inputProps={{ type: "number", min: "0" }}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      if (
                        parseInt(e.target.value) === 0 ||
                        e.target.value == "" ||
                        parseInt(e.target.value) <= parseInt(marks.maxMarks)
                      ) {
                        setMarks({ ...marks, passingMarks: e.target.value });
                      } else {
                        setStatus("Warning");
                        setMessage(
                          "Passing Marks Cannot Be Greater Than Maximum Marks"
                        );
                        setSnack(true);
                      }
                    } else {
                      setStatus("Warning");
                      setMessage("Negative Value Not Allowed");
                      setSnack(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center" sm>
              <Grid item container sm>
                <DateTimePicker
                  label="Exam Date *"
                  disablePast
                  inputVariant="outlined"
                  ampm={false}
                  fullWidth
                  value={examDate}
                  onChange={setExamDate}
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item onClick={handleDropZone}>
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                >
                  Upload *
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ color: "blue" }}
                >
                  {materialName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseUploadDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            disabled={
              myAttachment.attachment === "" ||
              myAttachment.attachment === " " ||
              examDate === null ||
              marks.passingMarks === 0 ||
              marks.maxMarks === 0 ||
              subjectSelected.subjectId === 0 ||
              myLoader === true
            }
            onClick={handleUploadSubmit}
          >
            {myLoader === false ? (
              "Upload"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>
        </DialogActions>
      </Dialog>



      {/* Question Mapping */}

      <Dialog
        open={questionMapping}
        onClose={() => setQuestionMapping(false)}
        aria-labelledby="customized-dialog-title"
        className={classes.questionMapping}
        fullWidth={true}
        maxWidth="md"

      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setQuestionMapping(false)}
        >
          Question Mapping
        </DialogTitle>
        <DialogContent
        >
          <Grid
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell component="th" style={{width: "10%"}}>
                        Q.No.
                  </TableCell>
                      <TableCell component="th" style={{width: "20%"}}>
                        Chapter Name
                  </TableCell>
                      <TableCell component="th" style={{width: "20%"}}>
                        Section Name
                  </TableCell>
                      <TableCell component="th" style={{width: "20%"}}>
                        Identifier
                  </TableCell>
                      <TableCell component="th" style={{width: "20%"}}>
                        Total Marks
                  </TableCell>
                      <TableCell component="th" style={{width: "10%"}}>
                        Action
                  </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {questionMappingData && questionMappingData.map((item, index) =>(
                    <TableRow key={index}>
                      <TableCell>
                        Q {index+1}
                      </TableCell>
                      <TableCell>
                        <TextField
                          id={`chapter${index}`}
                          label="Chapter Name"
                          select
                          name={`chapter${index}`}
                          variant="outlined"
                          fullWidth
                          value={item.chapterId}
                          className={classes.customDropdown}
                          onChange={(e) => addChapterId(index, e)}
                        >
                          {chapter && chapter.map((item1, index) => (
                            <MenuItem key={index} value={item1}>
                              {item1.chapterName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>

                      <TableCell>
                        <TextField
                          id={`section${index}`}
                          label="Section Name"
                          select
                          name={`section${index}`}
                          variant="outlined"
                          fullWidth
                          // value={item.chapterSectionId}
                          className={classes.customDropdown}
                          disabled={
                            questionMappingData[index].chapterSectionId.length === 0
                          }
                          onChange={(e) => {
                          }}
                        >
                          { questionMappingData[index].chapterSectionId.map((item1, index) => (
                            <MenuItem key={index} value={item1.chapterSectionId}>
                              {item1.sectionName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>

                      <TableCell>
                        <TextField
                          id={`identifier${index}`}
                          label="Identifier"
                          select
                          name={`identifier${index}`}
                          variant="outlined"
                          fullWidth
                          // value={selectSubject}
                          className={classes.customDropdown}
                          onChange={(e) => {
                          }}
                        >
                          {chapter && chapter.map((item1, index) => (
                            <MenuItem key={index} value={item1}>
                              {item1.chapterName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>

                      <TableCell>
                        <TextField
                          id={`totalmarks${index}`}
                          label="Total Marks"
                          name={`totalmarks${index}`}
                          variant="outlined"
                          fullWidth
                          // value={selectSubject}
                          className={classes.customDropdown}
                          onChange={(e) => {
                          }}
                        >
                        </TextField>
                      </TableCell>
                      <TableCell>
                      <IconButton
                      >
                        <DeleteOutlinedIcon style={{color: 'red', fontSize: '26px'}}/>
                    </IconButton>
                      </TableCell>
                      </TableRow>
                  ))}                    
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                variant="contained"
                color="secondary"
                style={{ color: "white", marginTop: '1em' }}
                onClick={addQuestionappingRow}
              >
              Add Question
          </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseUploadDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            disabled={
              myAttachment.attachment === "" ||
              myAttachment.attachment === " " ||
              examDate === null ||
              marks.passingMarks === 0 ||
              marks.maxMarks === 0 ||
              subjectSelected.subjectId === 0 ||
              myLoader === true
            }
            // onClick={handleUploadSubmit}
          >
            {myLoader === false ? (
              "Upload"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        open={dropZone}
        dialogProps={{ className: classes.myDropZone }}
        onClose={handleDropZoneClose}
        dropzoneParagraphClass={classes.dropZone}
        acceptedFiles={["image/*", "application/pdf"]}
        onSave={(files) => handleSaveMaterial(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack}
        autoHideDuration={9000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}

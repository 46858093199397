import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import TabMaker from "./TabMaker";
import TableMaker from "./TableMaker";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { groupBy } from "lodash";

import { url } from "./store.js";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  myDropZone: {
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
  },
}));
const tabheadings = window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ?
  [
    "DPP",
    "H W",
    "Assignment"
  ] : [
    "CW",
    "HW",
    "ASSIGNMENT",
    "PROJECT",
    "OBSERVATION",
    "RECORD",
  ];

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  dropZone: {
    color: "black !important",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_TASK_SECTION_WISE",
              busiParams: {
                active: true,
                sectionId: window.localStorage.sectionId,
                branchId: window.localStorage.branchId,
                secSubjectMappingId:
                  window.localStorage.teacherType == "Class"
                    ? 0
                    : window.localStorage.secSylMappingId,
                studentInstanceId: window.localStorage.studentInstanceId,
                resourceType: props.resourceType,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          let tempEvents = [];
          let row = [];

          if (data.respData[0].respMsg.result.length > 0) {
            data.respData[0].respMsg.result.map((myitem, index) => {
              tempEvents.push(myitem);
            });

            tempEvents.map((myitem, index) => {
              console.log(myitem);
              window.localStorage.teacherType == "Class"
                ? row.push([
                  { title: myitem.subjectName, type: "text" },
                  { title: myitem.chapterName, type: "text" },
                  { title: myitem.date, type: "text" },
                  { title: myitem.dueDate, type: "text" },

                  {
                    title: myitem.taskFileName,
                    type: myitem.taskFileType,
                    href: myitem.taskAttachment,
                  },
                  { title: myitem.comment, type: "text" },
                ])
                : row.push([
                  { title: myitem.subjectName, type: "text" },
                  { title: myitem.chapterName, type: "text" },
                  { title: myitem.date, type: "text" },
                  { title: myitem.dueDate, type: "text" },

                  {
                    title: myitem.taskFileName,
                    type: myitem.taskFileType,
                    href: myitem.taskAttachment,
                  },
                  { title: myitem.comment, type: "text" },
                  {
                    title: "",
                    type: "Action",
                    fullDetails: myitem,
                  },
                ]);
            });
          } else {
            row.push([
              { title: "No Data Available", type: "text" },
              { title: " ", type: "text" },
              { title: " ", type: "text" },
              { title: " ", type: "text" },

              {
                title: " ",
                type: "text",
              },
              { title: " ", type: "text" },
            ]);
          }

          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }
  }, [props.materialAdded]);

  return (
    <React.Fragment>
      {window.localStorage.teacherType == "Subject" ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            props.handleOpenUploadDialog(e, props.myid);
          }}
          style={{ color: "white", marginBottom: "0.5em" }}
        >
          ADD
        </Button>
      ) : undefined}
      <React.Fragment>
        {loader === true ? (
          <CircularProgress color="secondary" />
        ) : (
          <TableMaker columns={props.headers} rows={rows} />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default function WritingMaterial() {
  const classes = useStyle();
  const theme = useTheme();
  const [isReady, setIsReady] = useState(false);
  const [myTableData, setMyTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [chapter, setChapter] = useState([]);
  const [section, setSection] = useState([]);
  const [chapterSelected, setChapterSelected] = useState({ chapterId: null });
  const [sectionSelected, setSectionSelected] = useState({});
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
  });
  const [dueDate, setDueDate] = useState(null);
  const [comments, setComments] = useState("");
  const [resourceId, setResourceId] = useState(0);
  const [materialAdded, setMaterialAdded] = useState(false);
  const [myLoader, setMyLoader] = useState(false);
  const headers =
    window.localStorage.teacherType == "Class"
      ? ["Subject", "Chapter", "Date", "DueDate", "Attachment", "Comments"]
      : [
        "Subject",
        "Chapter",
        "Date",
        "DueDate",
        "Attachment",
        "Comments",
        "Action",
      ];
  const myTableData1 = [];
  const writingMaterialArr = window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ?
    [
      { name: "DPP", id: 7 },
      { name: "HW", id: 2 },
      { name: "ASSIGNMENT", id: 3 },
    ] : [
      { name: "CW", id: 1 },
      { name: "HW", id: 2 },
      { name: "ASSIGNMENT", id: 3 },
      { name: "PROJECT", id: 4 },
      { name: "OBSERVATION", id: 5 },
      { name: "RECORD", id: 6 },
    ];


  useEffect(() => {
    setLoader(true);
    const getData = async () => {
      writingMaterialArr.map((item, index1) => {
        console.log(item);
        let myid = item.id;

        let temList = (
          <MyDataMaker
            headers={headers}
            key={index1}
            resourceType={item.id}
            myid={myid}
            materialAdded={materialAdded}
            handleOpenUploadDialog={handleOpenUploadDialog}
          />
        );

        myTableData1.push(temList);
      });
    };

    getData().catch((data) => {
      console.log("sorry something went wrong");
      let temList = (
        <React.Fragment key={"CW"}>
          <Typography variant="body1">No Data Available</Typography>
        </React.Fragment>
      );
      myTableData1.push(temList);
    });

    setMyTableData(myTableData1);
    setIsReady(true);
    setInterval(() => {
      setLoader(false);
    }, 500);
  }, [materialAdded]);

  const handleOpenUploadDialog = async (e, id) => {
    setChapterSelected({});
    setResourceId(id);
    try {
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_ALL_CHAPTER_SECTION",
              busiParams: {
                admin: false,
                sectionId: window.localStorage.sectionId,
                subjectId: window.localStorage.subjectId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let mychapters = [];
          data.respData[0].respMsg.result &&
            data.respData[0].respMsg.result.map((item, index) => {
              mychapters.push(item);
            });
          setChapter(mychapters);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }
    setUploadDialog(true);
  };

  const handleDropZone = () => {
    setDropZone(true);
  };

  const handleSaveMaterial = async (files) => {
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".")[0];
    let myFileType = files[0].type.split("/")[1];

    setMyAttachment({
      ...myAttachment,
      attachment: newattachment,
      fileName: myFileName,
      fileType: myFileType,
    });
    setMaterialName(myFileName);
    setDropZone(false);
  };

  const handleChapterSelect = async (e) => {
    setChapterSelected(e.target.value);

    console.log(section);
  };

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  const handleUploadSubmit = () => {
    setMyLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_ADD_TO_DO_TASK",
              busiParams: {
                attachment: myAttachment.attachment,
                branchId: window.localStorage.branchId,
                chapterId: chapterSelected.chapterId,
                description: comments,
                dueDate: dueDate,
                fileName: myAttachment.fileName,
                fileType: myAttachment.fileType,
                name: chapterSelected.chapterName,
                resourceType: resourceId,
                secSubjectMappingId: window.localStorage.secSylMappingId,
                sectionId: window.localStorage.sectionId,
                subjectId: window.localStorage.subjectId,
                url: "",
                userId: window.localStorage.userId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          setMyLoader(false);
          setUploadDialog(false);
          setMaterialAdded(!materialAdded);
          setMyAttachment({
            attachment: "",
            fileName: "",
            fileType: "",
          });
          setDueDate(null);
          setComments("");
          setMaterialName("");
        })
        .catch((data) => {
          console.error(data);
          setUploadDialog(false);
          setMaterialName("");
        });
    } catch (error) {
      console.log(error);
      setUploadDialog(false);
      setMaterialName("");
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker
              tabs={tabheadings}
              data={myTableData}
              subjectBased={true}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
      <Dialog
        open={uploadDialog}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Upload Material
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em", scrollbarWidth: "none" }}>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ maxWidth: "40em" }}
          >
            {chapter && chapter.length <= 0 ? (
              <Grid item>
                <Typography variant="h6" color="secondary">
                  Please contact admin for chapter/section mapping
                </Typography>
              </Grid>
            ) : undefined}
            <Grid item style={{ maxWidth: "40em" }}>
              <TextField
                disabled
                variant="outlined"
                fullWidth
                label="Subject Name"
                value={
                  JSON.parse(window.localStorage.users)
                    ? JSON.parse(window.localStorage.users)[0].subjectName
                    : null
                }
              />
            </Grid>

            <Grid item style={{ maxWidth: "40em" }}>
              <TextField
                select
                variant="outlined"
                fullWidth
                label="Chapter Name"
                onChange={handleChapterSelect}
              >
                {chapter &&
                  chapter.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item.chapterName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            {
              //   <Grid item>
              //     <TextField
              //       select
              //       disabled={section.length > 0 ? false : true}
              //       variant="outlined"
              //       fullWidth
              //       label="Section Name"
              //       onChange={(e) => setSectionSelected(e.target.value)}
              //     >
              //       {section &&
              //         section.map((item) => (
              //           <MenuItem key={item} value={item}>
              //             {item.sectionName}
              //           </MenuItem>
              //         ))}
              //     </TextField>
              //   </Grid>
            }

            {
              // <Grid item>
              // <KeyboardDatePicker
              //       variant="inline"
              //       autoOk={true}
              //       name="date"
              //       inputVariant="outlined"
              //       label="Date"
              //       fullWidth
              //       placeholder="Select Notification Date"
              //       format="DD/MM/yyyy"
              //       value={resource.date}
              //       InputAdornmentProps={{ position: "end" }}
              //       onChange={(date) => {
              //         setResource({
              //           ...notification,
              //           dueDate: moment(date).format("YYYY-MM-DD").toString(),
              //         });
              //       }}
              //     />
              // </Grid>
            }
            <Grid item container spacing={2} alignItems="center">
              <Grid item>
                <KeyboardDatePicker
                  variant="inline"
                  autoOk={true}
                  disablePast
                  name="duedate"
                  inputVariant="outlined"
                  label="Due Date"
                  fullWidth
                  placeholder="Select Due Date"
                  format="DD/MM/yyyy"
                  value={dueDate}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(date) => {
                    setDueDate(moment(date).format("YYYY-MM-DD").toString());
                  }}
                />
              </Grid>
              <Grid item onClick={handleDropZone}>
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                >
                  Upload
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ color: "blue" }}
                >
                  {materialName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows="4"
                variant="outlined"
                fullWidth
                label="Comments"
                value={comments}
                inputProps={{
                  maxlength: 250,
                }}
                onChange={(e) => setComments(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseUploadDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            disabled={
              myAttachment.attachment === "" ||
              myAttachment.attachment === " " ||
              dueDate === null ||
              myLoader === true ||
              chapter.length === 0
            }
            onClick={handleUploadSubmit}
          >
            {myLoader === false ? (
              "Upload"
            ) : (
              <CircularProgress color={theme.palette.secondary.light} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={25000000}
        open={dropZone}
        dialogProps={{ className: classes.myDropZone }}
        dropzoneParagraphClass={classes.dropZone}
        acceptedFiles={["image/*", "application/pdf"]}
        onClose={handleDropZoneClose}
        onSave={(files) => handleSaveMaterial(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        filesLimit={1}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}

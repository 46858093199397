import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Backdrop,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  Toolbar,
  Switch,
  Tooltip,
  Paper,
  MenuItem,
} from "@material-ui/core";
import DisplayPdf from "./DisplayPdf";
import AvTimerRoundedIcon from "@material-ui/icons/AvTimerRounded";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import { url, makeReqBody } from "./store.js";
import { set } from "date-fns";
import { IndeterminateCheckBox } from "@material-ui/icons";
import NumberFormat from "react-number-format";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

const inverDirect = {
  asc: "desc",
  desc: "asc",
};

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? "#616161"
        : "transparent",
    minwidth: "30em",
  },
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    fontSize: "0.85rem",
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color:
        theme.typography.myTheme.themeName === "Night"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
    backgroundColor:
      theme.typography.myTheme.themeName === "Night"
        ? theme.palette.common.ltgrey
        : theme.palette.common.white,
    width: "100%",
  },
  tableBody:{
    backgroundColor:
    theme.typography.myTheme.themeName === "Night"
    ? theme.palette.common.white
    : theme.palette.common.white,
    width: "100%",
  },
  toolBar: {
    ...theme.mixins.toolbar,
  },

  buttonStyle: {
    padding: "0.5em",
    borderRadius: 5,
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },
  myDropZone: {
    "& .MuiDialogContent-root": {
      scrollbarWidth: "none",
    },
  },

  videoAppBar: {
    backgroundColor: theme.palette.common.ltgrey,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.common.black,
  },
  switchButton: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.common.white,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.light,
    },

    "& .MuiSwitch-colorSecondary": {},
  },
  pdfContainer: {
    marginTop: "none",
  },

  pdfContainer2: {
    width: "200em",
  },
  titlebar: {
    //  height: "6px",
    backgroundColor: theme.palette.common.ltgrey,
  },

  pdfScroll: {
    overflowY: "auto",
    
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',  
    }
  },
  customInput:{
    "& .MuiInputLabel-outlined": {
      zIndex: '1',
      transform: "translate(12px, 14px) scale(1)",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 12px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(12px, -6px) scale(0.75)",
    },
  },
  dialogContainer:{
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
    "& .MuiDialogContent-root": {
      minHeight: '12em',  
      
    },
    "& ::-webkit-scrollbar": {
      width: '7px!important',
      height: '5px',
    },
    "& ::-webkit-scrollbar-track:": {
      background: "#00256f",
    },
    
    "& ::-webkit-scrollbar-thumb": {
      background: "#f4ce2c",
    },
    
    "& ::-moz-scrollbar": {
      width: '10px',
      scrollbarWidth: 'thin',
    },
    
    "& ::-moz-scrollbar-track":{
      background: '#00256f',
    },
    
    "& ::-moz-scrollbar-thumb": {
      background: "#f4ce2c",
    },
  },
  stickyBx:{
    paddingTop: '10px',
    marginBottom: '15px',
    position: 'sticky',
    top: '0',
    zIndex: '2',
    background: '#fff',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  iconButton:{
    padding: '6px',
},
customTooltip: {
  // I used the rgba color for the standard "secondary" color
  fontSize: "0.85rem",
},
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function EExamVerify(props) {
  const classes = useStyle();
  const theme = useTheme();

  const [uploadDialog, setUploadDialog] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [materialName, setMaterialName] = useState("");
  const [resubmitDate, setResubmitDate] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [hrefData, setHrefData] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [displayFullscreen, setDisplayFullscreen] = React.useState(false);
  const [myAttachment, setMyAttachment] = useState({
    attachment: "",
    fileName: "",
    fileType: "",
  });
  const [imageOpen, setImageOpen] = useState(false);
  const [studentDetails, setStudentDetails] = useState({});
  const [passMarks, setPassMarks] = useState(0);
  const [reUpload, setReUpload] = useState(false);
  const [questionMarks, setQuestionMarks] = useState(false);
  const [totalMarksCount, setTotalMarksCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const headers = [
    "Student",
    "Roll No",
    "Subject",
    "Marks",
    "Marks Details",
    "Date Verified",
    "Attachment",
    "Status",
    "Comments",
    "Actions",
  ];

  const [backLoader, setBackLoader] = useState(false);
  const [dropZoneOmr, setDropZoneOmr] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handleSaveMaterial = async (files) => {

    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];
    let myFileName = files[0].name.split(".")[0];
    let myFileType = files[0].type.split("/")[1];

    setMyAttachment({
      ...myAttachment,
      attachment: newattachment,
      fileName: myFileName,
      fileType: myFileType,
    });
    setMaterialName(myFileName);
    setDropZone(false);
    setIsChanged(true);
  };

  const uploadOMR = async (files) => {
    if(pageNo ===  0){
      setMessage("Please select page no.");
      setStatus("error");
      setSnack(true);
      setDropZoneOmr(false);
    }
    else{
    console.log("Files:", files[0]);
    let attachment = await toBase64(files[0]);
    let newattachment = attachment.split("base64,")[1];

    let offset = (pageNo - 1) * 20;
    let offsetLimit = 20;

    try {
      fetch(
          url,
          makeReqBody("SCHOOL_OMR_SCAN_INTERMEDIATE", {
            examId: studentDetails.eExamId,
            image: newattachment,
            offset: offset,
            pageNo: pageNo,
            studentInstanceId: studentDetails.studentInstanceID
          })
      )
          .then((response) => response.json())
          .then((data) => {           
            let marksLastIndex = offset + offsetLimit;
            if(data.respData[0].respMsg.status !== "Failure"){    
              let questionData = [...questionMarks];
              let omrData = data.respData[0].respMsg.result;
             
                for(let index = offset; index < marksLastIndex; index++){
                  for(let omrIndex = 0; omrIndex < omrData.length; omrIndex++){
                    if(index < questionData.length ){
                      if(questionData[index].examQuesStuMapId === omrData[omrIndex].examQuesStuMapId){
                        questionData[index].marks = omrData[omrIndex].marks === "NA" ? "" : omrData[omrIndex].marks;
                      }
                    }                 
                  }
                }
              console.log("Data--------", questionData);
              console.log("omrData Data--------", omrData);
              setQuestionMarks(questionData);

              let sum = 0;
              for (let i = 0; i < questionMarks.length; i++) {                              
              if(questionMarks[i].marks !== ""){
              sum += parseFloat(questionMarks[i].marks);
              }
              }
              setTotalMarksCount(sum);
            }
            setMessage(data.respData[0].respMsg.message);
            data.respData[0].respMsg.result.status === "Failure"
              ? setStatus("error")
              : setStatus("success");
            setSnack(true);
            setDropZoneOmr(false);
          })
          .catch((data) => {
              console.error(data);
              setDropZoneOmr(false);
          });
  } catch (error) {
      console.error(error);
      setDropZoneOmr(false);
  }
}
  }

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleImageOpen = (e, href) => {
    setImageOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };
  // const resetExamVerify = () => {
  //   setStudentDetails({
  //     comments: "",
      
  //   });
  //   setMaterialName("");
  //   setMyAttachment({
  //     ...myAttachment,
  //     attachment: '',
  //     fileName: '',
  //     fileType: '',
  //   });
  //   setPassMarks("");
  // }

  const handleDropZoneClose = () => {
    setDropZone(false);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
    setQuestionMarks([]);
    setMaterialName("");
    setTotalMarksCount(0);
    setIsChanged(false);
  };

  const handleUploadSubmit = () => {
    if (!isChanged) {
      // If no changes, prevent submission or notify the user
      setMessage("No changes detected.");
      setStatus("info");
      setSnack(true);
      return;
  }
    if(studentDetails.totalMarks < totalMarksCount){
      setMessage("Marks obtained cannot be greater than Total marks.");
      setStatus("error")
      setSnack(true);
      return false;
    }
      for(let index=0; index<questionMarks.length; index++){
        if(questionMarks[index].marks > questionMarks[index].totalMarks){
          setMessage("Detailed marks do not match with total marks of exam.");
          setStatus("error")
          setSnack(true);
          return false;
        }
    }
    try {
      setBackLoader(true);
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPLOAD_EXAM_RESULT",
              busiParams: {
                examTxnId: studentDetails.txnId,
                loginUserId: window.localStorage.userId,
                marks: totalMarksCount,
                uploadMarksDetail: myAttachment.attachment,
                fileName: myAttachment.fileName,
                fileType: myAttachment.fileType,
                comments: studentDetails.comments,
                quesMarks: questionMarks,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setBackLoader(false);
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          setMaterialName("");
          setPassMarks(0);
          props.setVerifyEExamRow([]);
          setQuestionMarks([]);
          setTimeout(() => {
            props.handleVerifyEExam(studentDetails);
            setUploadDialog(false);
          }, 1500);
        })
        .catch((data) => {
          console.error(data);
        });
    } catch (error) {
      console.log(error);
    }

 
  };

  const handleReUploadSubmit = () => {
    if(resubmitDate === null){
      setMessage("Please select date.");
      setStatus("error");
      setSnack(true);
      return false;
    }
    if(Date.parse(resubmitDate) < Date.parse(new Date())){
      setMessage("Exam time cannot be less than system time.");
      setStatus("error");
      setSnack(true);
      return false;
    }
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_UPDATE_RESUBMISSION_DETAILS",
              busiParams: {
                eExamTxnId: studentDetails.txnId,
                loginUserId: window.localStorage.userId,
                resubmitDate: moment(resubmitDate)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .toString(),
                studentInstanceId: studentDetails.studentInstanceID,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setMessage(data.respData[0].respMsg.message);
          data.respData[0].respMsg.result.status == "Failure"
            ? setStatus("error")
            : setStatus("success");
          setSnack(true);
          //setMaterialName("");
          props.setVerifyEExamRow([]);
          setLoader(false);
          setResubmitDate(null);
          setReUpload(false);
          setTimeout(() => {
            props.handleVerifyEExam(studentDetails);
          }, 1500);
        })
        .catch((data) => {
          console.error(data);
          setLoader(false);
          setReUpload(false);
        });
    } catch (error) {
      console.log(error);
      setLoader(false);
      setReUpload(false);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePdfOpen = (e, href) => {
    setOpen(true);
    setHrefData(href);
    window.localStorage.setItem("idleDisable", true);
  };

  const handlepdfClose = (event) => {
    setOpen(false);
    window.localStorage.removeItem("idleDisable");
  };
  const toggleView = (event) => {
    setDisplayFullscreen(!displayFullscreen);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleVerification = (e, details) => {
    console.log(details);    
    if(details.examTxnStatus === "Verified"){
      setTotalMarksCount(details.marks);
      setMyAttachment({
        ...myAttachment,
        attachment: details.uploadMarksDetail,
        fileName: details.uploadMarksfileName,
        fileType: details.uploadMarksfileType,
      });
    }else{
      setTotalMarksCount(0);
      setMyAttachment({
        ...myAttachment,
        attachment: "",
        fileName: "",
        fileType: "",
      });
    }
    console.log(totalMarksCount);
    try {
      fetch(
          url,
          makeReqBody("SCHOOL_GET_STU_QUES_MARKS_DETAILS", {
            examId: details.eExamId,
            studentInstanceId: details.studentInstanceID
          })
      )
          .then((response) => response.json())
          .then((data) => {
              setQuestionMarks(data.respData[0].respMsg.result);
          })
          .catch((data) => {
              console.error(data);
          });
  } catch (error) {
      console.error(error);
  }
    setStudentDetails(details);    
    setUploadDialog(true);
  };
  const handleReUpload = (e, details) => {
    console.log(details);
    setStudentDetails(details);
    setReUpload(true);
  };

const totalQuestion = questionMarks && questionMarks.length;
const totalPage =  Math.ceil(totalQuestion/5);

  return (

    <React.Fragment>      
      <Button
        variant="contained"
        color="secondary"
        style={{ color: "white", marginLeft: "1em", marginTop: "-0.5em" }}
        onClick={() => props.setRoute("table")}
      >
        Back
      </Button>

      <Grid item container direction="column" spacing={1}>
        <Grid item>
          {props.rows.length > 0 ? (
            <TableContainer
              className={classes.table}
              style={{ scrollbarWidth: "none" }}
            >
              <Table aria-label="simple table" className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {headers &&
                      headers.map((item, index) => (
                        <TableCell
                          key={item + index}
                          style={{ cursor: "pointer" }}
                        >
                          <Grid container>
                            <Grid item>{item}</Grid>
                            {
                              // <Grid item>
                              //   {item === columnToSort ? (
                              //     sortDirection === "asc" ? (
                              //       <ArrowDropUpIcon
                              //         size="small"
                              //         style={{ marginLeft: "0.75em" }}
                              //       />
                              //     ) : (
                              //       <ArrowDropDownIcon
                              //         size="small"
                              //         style={{ marginLeft: "0.75em" }}
                              //       />
                              //     )
                              //   ) : (
                              //     <SortIcon
                              //       size="small"
                              //       style={{ marginLeft: "0.75em" }}
                              //     />
                              //   )}
                              // </Grid>
                            }
                          </Grid>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rows &&
                    props.rows.map((item, index) => (
                      <React.Fragment key={item + index}>
                        <TableRow key={item.title + index}>
                          {item.map((row, index) => (
                            <TableCell
                              key={row + index}
                              onClick={
                                row.type === "pdf"
                                  ? (e) => handlePdfOpen(e, row.href)
                                  : row.type === "png" ||
                                    row.type === "jpg" ||
                                    row.type === "jpeg"
                                  ? (e) => handleImageOpen(e, row.href)
                                  : null
                              }
                              style={{
                                maxWidth: "10em",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {index === 0 ? (
                                <Tooltip title={row.title} arrow>
                                  <p>{row.title}</p>
                                </Tooltip>
                              ) : (
                                <span
                                  className={classes.buttonStyle}
                                  style={{
                                    color:
                                      row.type === "link" ||
                                      row.type === "video" ||
                                      row.type === "pdf" ||
                                      row.type === "upload" ||
                                      row.type === "doc" ||
                                      row.type === "mp4" ||
                                      row.type === "mp3" ||
                                      row.type === "3gp" ||
                                      row.type === "vigilance" ||
                                      row.type === "png" ||
                                      row.type === "jpg" ||
                                      row.type === "jpeg" ||
                                      row.type === "uploadExam"
                                        ? theme.palette.common.blue
                                        : row.type === "status"
                                        ? "white"
                                        : "inherit",
                                    cursor: "pointer",
                                    backgroundColor:
                                      row.type === "status" && row.title
                                        ? row.title.toLowerCase() ===
                                            "Completed".toLowerCase() ||
                                          row.title.toLowerCase() ===
                                            "Verified".toLowerCase() ||
                                          row.title.toLowerCase() ===
                                            "Submitted".toLowerCase()
                                          ? theme.palette.common.completed
                                          : row.title.toLowerCase() ===
                                              "Expired".toLowerCase() ||
                                            row.title.toLowerCase() ===
                                              "Re-Submitted".toLowerCase() ||
                                            row.title.toLowerCase() ===
                                              "Not Submitted".toLowerCase() ||
                                            row.title.toLowerCase() ===
                                              "Resubmit".toLowerCase()
                                          ? theme.palette.common.warning
                                          : row.title.toLowerCase() ===
                                              "Pending".toLowerCase() ||
                                            row.title.toLowerCase() ===
                                              "Pending For Verification".toLowerCase()
                                          ? theme.palette.common.pending
                                          : row.title === "To Be Uploaded"
                                          ? theme.palette.common.toupdate
                                          : "inherit"
                                        : "inherit",
                                  }}
                                >
                                  {row.type === "verifyWritingAction" ? (
                                    <React.Fragment>
                                      <IconButton
                                        onClick={(e) =>
                                          handleVerification(e, row.fullDetails)
                                        }
                                        style={{ padding: 0, marginRight: 10 }}
                                      >
                                        <AssignmentOutlinedIcon color="secondary" />
                                      </IconButton>
                                      {row.fullDetails.examType !== 2 && row.fullDetails.examTxnStatus !== "Verified" && <IconButton
                                        onClick={(e) =>
                                          handleReUpload(e, row.fullDetails)
                                        }
                                        style={{ padding: 0 }}
                                      >
                                        <AvTimerRoundedIcon color="secondary" />
                                      </IconButton>}
                                    </React.Fragment>
                                  ) : (
                                    <Tooltip
                                      title={row.title}
                                      classes={{
                                        tooltip: classes.customTooltip,
                                      }}
                                    >
                                      <span>{row.title}</span>
                                    </Tooltip>
                                  )}
                                </span>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <CircularProgress color="secomdary" />
          )}
        </Grid>
      </Grid>

      <Dialog
        open={uploadDialog}
        onClose={handleCloseUploadDialog}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="lg"
        className={classes.dialogContainer}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseUploadDialog}
        >
          Verify Exam
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em", paddingTop: '0' }}>
          <Grid container direction="row" spacing={2} className={classes.stickyBx}>
            <Grid item sm={3}>
              <TextField
                disabled
                variant="outlined"
                fullWidth
                label="Student Name"
                value={studentDetails.studentName}
                className={classes.customInput}
              />
            </Grid>
            <Grid item sm={3}>
              <TextField
                disabled
                variant="outlined"
                fullWidth
                label="Subject Name"
                value={studentDetails.subjectName}
                className={classes.customInput}
              />
            </Grid>
              <Grid item container sm={3}>
                <TextField
                  disabled
                  variant="outlined"
                  fullWidth
                  label="Total Marks"
                  value={studentDetails.totalMarks}
                  className={classes.customInput}
                />
              </Grid>
              <Grid item container sm={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Marks Obtained *"
                  inputProps={{ type: "number" }}
                  value={totalMarksCount}
                  disabled
                  className={classes.customInput}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      if (
                        parseInt(e.target.value) === 0 ||
                        e.target.value == "" ||
                        parseInt(e.target.value) <=
                          parseInt(studentDetails.totalMarks)
                      ) {
                        setPassMarks(e.target.value);
                        setIsChanged(true);
                      } else {
                        setStatus("Warning");
                        setMessage(
                          "Obtained Marks Cannot Be Greater Than Total Marks"
                        );
                        setSnack(true);
                      }
                    } else {
                      setStatus("Warning");
                      setMessage("Negative Value Not Allowed");
                      setSnack(true);
                    }
                  }}
                />                
              </Grid>  
              </Grid>  
               <Grid container direction="row" spacing={2}>
                {/* <Grid item sm={6}>
                  <TextField 
                  select
                    // value={item.remarks === "" ? null : item.remarks  }                   
                    variant="outlined"
                    // id={`commentS${index}`}
                    fullWidth
                    label="Select Page"
                    className={classes.customInput}
                    // inputProps={{ maxLength: 250 }}                     
                    onChange={(e)=>{
                     setPageNo(e.target.value);
                    }}
                  >
                   {Array.from(Array(totalPage), (e, i) => {
                     return <MenuItem key={i} value={i+1}>{`Page ${i+1}`}</MenuItem>
                   })}

                  </TextField>
                </Grid>

                <Grid item sm={6}>
                <Typography
                  variant="h6"
                  color="Secondary"
                  onClick={() => setDropZoneOmr(true)}
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                >
                  Upload OMR Front Sheet
                </Typography>
                </Grid>  */}
            <Grid item sm={12}>
            <TableContainer
            component={Paper}
              // className={classes.table}
            >
              <Table aria-label="simple table" className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell component="th" style={{width: '15%', fontWeight: '700'}}>
                      Question No.
                    </TableCell>
                    <TableCell component="th" style={{width: '13%', fontWeight: '700'}}>
                      Marks
                    </TableCell>
                    <TableCell component="th" style={{width: '15%', fontWeight: '700'}}>
                      Total Marks
                    </TableCell>
                    <TableCell component="th" style={{width: '57%', fontWeight: '700'}}>
                     Comment
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.tableBody}>
                {questionMarks && questionMarks.map( (item, index) => (
                    <TableRow key={index+1}>
                    <TableCell>
                      Question {index+1}
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                          value={item.marks}
                          customInput={TextField}
                          variant="outlined"
                          id={`markS${index}`}
                          fullWidth
                          label="Marks"
                          className={classes.customInput}
                          allowNegative={false}
                          inputProps={{ maxlength: "4", }}
                          decimalScale={1}
                          onChange={(e)=>{
                            let data = [...questionMarks];
                            data[index].marks = e.target.value;
                            setQuestionMarks(data);
                              var sum = 0;
                              for (var i = 0; i < questionMarks.length; i++) {                              
                                if(questionMarks[i].marks != ""){
                                  sum += parseFloat(questionMarks[i].marks);
                                }
                              }
                            setTotalMarksCount(sum);
                            setIsChanged(true);
                            console.log(questionMarks);
                          }}
                        
                        />
                    </TableCell>
                    <TableCell>
                     {item.totalMarks}
                    </TableCell>
                    <TableCell>
                    <TextField
                      value={item.remarks === "" ? null : item.remarks  }                   
                      variant="outlined"
                      id={`commentS${index}`}
                      fullWidth
                      label="Comment"
                      className={classes.customInput}
                      inputProps={{ maxLength: 250 }}                     
                      onChange={(e)=>{
                        let data = [...questionMarks];
                        data[index].remarks = e.target.value.replace(/['"$&]/g, "");
                        setQuestionMarks(data);
                      }}
                    />
                    </TableCell>
                  </TableRow>                  
                ))
                }
                </TableBody>
              </Table>
            </TableContainer>
            </Grid>        
              <Grid item>
                {" "}
                <Typography
                  variant="h6"
                  color="Secondary"
                  onClick={() => setDropZone(true)}
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                >
                  Upload
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: "1em" }}>
                {" "}
                <Typography
                  variant="h6"
                  color="Secondary"
                  onClick={() => setDropZone(true)}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                  }}
                >
                  {materialName === "" ? studentDetails.examTxnStatus === "Verified" ? studentDetails.uploadMarksfileName : '' : materialName} 
                </Typography>
              </Grid>
            <Grid item sm={12}>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={studentDetails.comments}
                label="Comments"
                inputProps={{ maxLength: 250, }} 
                onChange={(e) =>{
                  setStudentDetails({
                    ...studentDetails,
                    comments: e.target.value.replace(/['"$&]/g, "")
                  });
                  setIsChanged(true);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseUploadDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ color: "white" }}
            color="secondary"
            onClick={handleUploadSubmit}
            disabled={!isChanged || totalMarksCount == -1 || studentDetails.examType == 1 && myAttachment.attachment.trim() === ""}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={reUpload}
        onClose={() => setReUpload(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setReUpload(false)}
        >
          Allow Reupload
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Grid container direction="column" spacing={2}>
            <Typography variant="h6" color="secondary">
              Are you sure you want to extend the upload time for this student?
            </Typography>
            <Grid item container spacing={2}>
              <Grid item container sm>
                <TextField
                  disabled
                  variant="outlined"
                  fullWidth
                  label="Student Name"
                  value={studentDetails.studentName}
                />
              </Grid>
              <Grid item container sm>
                <TextField
                  disabled
                  variant="outlined"
                  fullWidth
                  label="Subject Name"
                  value={studentDetails.subjectName}
                />
              </Grid>
            </Grid>

            <Grid item>
              <DateTimePicker
                label="Select Date"
                disablePast
                inputVariant="outlined"
                ampm={false}
                color="secondary"
                style={{
                  color: theme.palette.common.tabFont,
                }}
                fullWidth
                value={resubmitDate}
                onChange={setResubmitDate}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setReUpload(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ color: "white" }}
            color="secondary"
            onClick={handleReUploadSubmit}
          >
            Update Upload Date
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        open={dropZone}
        dialogProps={{ className: classes.myDropZone }}
        onClose={handleDropZoneClose}
        onSave={(files) => handleSaveMaterial(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        acceptedFiles={["image/*", "application/pdf"]}
      />

      <DropzoneDialog
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        maxFileSize={6000000}
        open={dropZoneOmr}
        dialogProps={{ className: classes.myDropZone }}
        onClose={()=>setDropZoneOmr(false)}
        onSave={(files) => uploadOMR(files)}
        showPreviews={true}
        showFileNamesInPreview={true}
        acceptedFiles={["image/*"]}
      />

     

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                fontSize="small"
                style={{ color: theme.palette.common.tabFont }}
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>

      <Dialog
        fullScreen={displayFullscreen}
        open={open}
        onClose={handlepdfClose}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlepdfClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: theme.palette.common.tabFont }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}></Typography>
            <Switch
              checked={checked}
              onChange={toggleChecked}
              onClick={toggleView}
              color="primary"
            />
            <Typography style={{ color: theme.palette.common.tabFont }}>
              Toggle View
            </Typography>
            <Tooltip
            title="Download Pdf"
            classes={{
              tooltip: classes.customTooltip,
            }}>
              <IconButton className={classes.iconButton} onClick={()=> window.open(hrefData)}>
                <GetAppOutlinedIcon color="primary"/>
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <Grid container direction="column" alignItems="center" className={`${classes.pdfScroll} customPdfScroll`}>
          <Grid item>
            <DisplayPdf data={hrefData} displayFullscreen={displayFullscreen} />
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={imageOpen}
        onClose={handleImageClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleImageClose}
        ></DialogTitle>
        <DialogContent>
          <img
            src={hrefData}
            alt="viewer"
            style={{ height: "40em", width: "40em" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleImageClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={backLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Snackbar, TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import ExamTabMakerAnalysis from '../Exam/ExamTabMakerAnalysis';
import { makeReqBody, url } from './store';
import TableMaker from './TableMaker';
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import RectificationExamPage from './RectificationExamPage';



const useStyle = makeStyles((theme) => ({
    subContainer: {
        ...theme.typography.subContainer,
    },
    customInput: {
        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: "translate(12px, 14px) scale(1)",
        },
        "& .MuiOutlinedInput-input": {
            padding: "12px 12px",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(12px, -6px) scale(0.75)",
        },
    },
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "60em",
        },
        "& MuiDialog-paperWidthSm": {
            maxWidth: "100em",
        },
        "& .MuiDialogContent-root": {
            minHeight: '12em',

        },
        "& ::-webkit-scrollbar": {
            width: '7px!important',
            height: '5px',
        },
        "& ::-webkit-scrollbar-track:": {
            background: "#00256f",
        },

        "& ::-webkit-scrollbar-thumb": {
            background: "#f4ce2c",
        },

        "& ::-moz-scrollbar": {
            width: '10px',
            scrollbarWidth: 'thin',
        },

        "& ::-moz-scrollbar-track": {
            background: '#00256f',
        },

        "& ::-moz-scrollbar-thumb": {
            background: "#f4ce2c",
        },
    },
}))


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const MyDataMaker = (props) => {
    const classes = useStyle();
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(false);
    const [reRender, setRerender] = useState(false);
    const [changeTableData, setChangeTableData] = useState(false);
    const [openDailog, setOpenDailog] = useState(false);
    const [studentData, setStudentData] = useState({});
    const [comments, setComments] = useState("")
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const theme = useTheme();
    const [studentlist, setStudentList] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState({})




    useEffect(() => {
        setLoader(true);
        try {
            if (window.localStorage.boardId > 6 && window.localStorage.boardId < 10) {
                fetch(url, makeReqBody("SCHOOL_SMART_QBT_LIST_OF_STUDENT_BY_EXAM", {
                    examTermId: props.examTermId,
                    sectionId: parseInt(window.localStorage.sectionId),
                    branchId: window.localStorage.branchId,
                    subjectId: window.localStorage.teacherType === "Class" ? 0 : parseInt(window.localStorage.subjectId)
                }))
                    .then((response) => response.json())
                    .then((data) => {
                        setStudentList(data.respData[0].respMsg.result.stuMarksRes)

                        setTimeout(() => {
                            setLoader(false);
                        }, 1000);
                    }).catch((data) => {
                        console.error(data);
                        setTimeout(() => {
                            setLoader(false);
                        }, 1000);
                    })
            } else {
                fetch(url, makeReqBody("SCHOOL_EA_STUDENT_MARKS", {
                    branchId: window.localStorage.branchId,
                    examTermId: props.examTermId,
                    sectionId: window.localStorage.sectionId,
                    secSubMappingId: window.localStorage.teacherType == "Class" ? 0 : window.localStorage.secSylMappingId,
                })).then(res => res.json())
                    .then(data => {
                        setStudentList(data.respData[0].respMsg.result.stuMarksRes)

                        setTimeout(() => {
                            setLoader(false);
                        }, 1000);
                    }).catch((data) => {
                        console.error(data);
                        setTimeout(() => {
                            setLoader(false);
                        }, 1000);
                    })
            }
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }, [reRender]);


    const headers = window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? [
        "Exam Name",
        "Created",
        "Due Date",
        "Action",
        "Status",
    ] : [
        "Subject",
        "Generated Rectification Work",
        "Created",
        "Due Date",
        "Uploaded Rectification Work",
        "Verify",
        "Status",
        "Comments"
    ];



    const handleViewRectifyDetail = (data) => {
        setChangeTableData(true)
        setLoader(true);
        try {
            fetch(url, makeReqBody(window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? "SCHOOL_SMART_QBT_RECTIFICATION" : "SCHOOL_EXAM_RECTIFICATION", {
                examTermId: data.examTermId,
                studentInstanceId: data.studentInstanceID || data.studentInstanceId,
                sectionId: parseInt(window.localStorage.sectionId),
                branchId: parseInt(window.localStorage.branchId),
                subjectId: window.localStorage.teacherType === "Class" ? 0 : window.localStorage.subjectId
            })).then(resp => resp.json())
                .then(data => {
                    let row = [];
                    data.respData[0].respMsg.result.length > 0 ?
                        data.respData[0].respMsg.result.map(x => {
                            window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ?
                                row.push([
                                    { title: x.examName, type: "text" },
                                    { title: x.createdOn, type: "text" },
                                    { title: x.dueDate, type: "text" },
                                    { title: "View Exam", fullDetails: x, type: "takeRectificationExam" },
                                    { title: x.rectifyStatus, type: "text" },
                                ])
                                : row.push([
                                    { title: x.subjectName, type: "text" },
                                    {
                                        title: x.questUrl === null ||
                                            x.questUrl === undefined ||
                                            x.questUrl.trim() === "" ||
                                            x.questfile,
                                        type: x.questUrl === null ||
                                            x.questUrl === undefined ||
                                            x.questUrl.trim() === "" ||
                                            x.questfileType,
                                        href: x.questUrl,
                                    },
                                    { title: x.createdOn, type: "text" },
                                    { title: x.dueDate, type: "text" },
                                    {
                                        title: x.ansUrl === null ||
                                            x.ansUrl === undefined ||
                                            x.ansUrl.trim() === "" ||
                                            x.ansfile,
                                        type: x.ansUrl === null ||
                                            x.ansUrl === undefined ||
                                            x.ansUrl.trim() === "" ||
                                            x.ansfileType,
                                        href: x.ansUrl,
                                    },
                                    { fullDetails: x, typeFeature: "rectifyVerify" },
                                    { title: x.rectifyStatus, type: "text" },
                                    { title: x.remarks, type: "text" },
                                ])
                        })
                        : row.push([
                            { title: "No Data Available", type: "text" },
                            { title: "", type: "text" },
                            { title: "", type: "text" },
                            { title: "", type: "text" },
                            { title: "", type: "text" },
                            { title: "", type: "text" },
                        ]);

                    setRows(row);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                })
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    };


    const handleRectifyComments = (data) => {
        setOpenDailog(true);
        setStudentData(data);
    };

    const handleVerifyRectification = () => {
        if (comments === "") {
            setMessage("Please comment.");
            setSnack(true);
            setStatus("Error")
            return
        }
        setLoader(true)
        fetch(url, makeReqBody("SCHOOL_EXAM_RECTIFICATION_REMARKS_BY_TEACHER", {
            erectifyId: studentData.erectifyId,
            comments: comments,
        })).then(res => res.json())
            .then(data => {
                handleViewRectifyDetail(studentData)
                setComments("")
                if (data.respData[0].respMsg.status === "Success") {
                    setMessage(data.respData[0].respMsg.message);
                    setSnack(true);
                    setStatus("Success")
                } else {
                    setMessage(data.respData[0].respMsg.message);
                    setSnack(true);
                    setStatus("Error")
                }
                setOpenDailog(false)
            }).catch(error => {
                console.error(error);
                setTimeout(() => {
                    setLoader(false)
                }, 1000);
            })
    }


    return (
        <React.Fragment>
            <Grid item sm={3} style={{ marginBottom: "0.5em" }}>
                <TextField
                    id="studentList"
                    label="Student List*"
                    select
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={selectedStudent}
                    onChange={(e) => {
                        setSelectedStudent(e.target.value);
                        handleViewRectifyDetail(e.target.value)
                        console.log(e.target.value);
                    }}
                >
                    {studentlist && studentlist.map((item, index) => (
                        <MenuItem key={index} value={item}>
                            {item.studentName}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {loader === true ? (
                <CircularProgress color="secondary" />
            ) : (
                <>
                    {Object.keys(selectedStudent).length !== 0 &&
                        <TableMaker
                            columns={headers}
                            rows={rows}
                            toReload={props.toReload}
                            setToReLoad={props.setToReLoad}
                            handleViewRectifyDetail={handleViewRectifyDetail}
                            handleRectifyComments={handleRectifyComments}
                            handleTakeRectificationExam={props.handleTakeRectificationExam}
                        />}
                </>
            )}

            <Dialog
                open={openDailog}
                onClose={() => setOpenDailog(false)}
                aria-labelledby="customized-dialog-title"
                className={classes.dialogContainer}
            >
                <DialogTitle id="form-dialog-title">Verify</DialogTitle>
                <DialogContent style={{ scrollbarWidth: "none" }}>
                    <TextField
                        value={comments}
                        variant="outlined"
                        id={`comments`}
                        fullWidth
                        multiline
                        rows={2}
                        label="Comment"
                        className={classes.customInput}
                        inputProps={{ maxLength: 250 }}
                        onChange={(e) => {
                            let value = e.target.value.replace(/['"$&]/g, "");
                            console.log(value)
                            setComments(value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDailog(false)}
                        variant="outlined"
                        color="secondary"
                        style={{ marginRight: "1em" }}
                    > Close</Button>
                    <Button
                        onClick={handleVerifyRectification}
                        variant="contained"
                        color="secondary"
                        style={{ textTransform: "none", background: '#d95350' }}
                    > {loader === true ? (
                        <CircularProgress color="secondary" />
                    ) : "Submit"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                style={{ zIndex: theme.zIndex.drawer + 1100 }}
                open={snack}
                autoHideDuration={9000}
                onClose={() => setSnack(false)}
                action={<>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnack(false)}
                    >
                        <CloseIcon
                            style={{ color: theme.palette.common.tabFont }}
                            fontSize="small"
                        />
                    </IconButton>
                </>}
            >
                <Alert severity={status}>{message}</Alert>
            </Snackbar>
        </React.Fragment>
    );
};


export default function RectificationWorkTeacher() {

    const classes = useStyle();
    const [subjectManager, setSubjectManager] = useState([]);
    const [tabhead, setTabHead] = useState([]);
    const [loader, setLoader] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [showTakeExam, setShowTakeExam] = useState(false);
    const [studentData, setStudentData] = useState({})


    useEffect(() => {
        setLoader(true);
        const getQueryData = async () => {
            let termData = [];
            let subjectName = [];
            await fetch(url, makeReqBody("SCHOOL_GET_EXAM_TERMS_FOR_BRANCH", window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? {
                id: window.localStorage.branchId,
                sectionId: window.localStorage.sectionId
            } : {
                id: window.localStorage.branchId,
                standard: window.localStorage.className,
            }))
                .then((response) => response.json())
                .then((data) => {
                    data.respData[0].respMsg.result.map((item, index1) => {
                        subjectName.push(item.name);
                        termData.push(item);
                    });
                    setTabHead(subjectName);
                })
                .catch((data) => {
                    console.log("sorry something went wrong");
                });
            try {
                let AllLists = [];

                termData.map((item, index) => {
                    let temList = (
                        <MyDataMaker
                            key={index}
                            examTermId={item.examTermId}
                            handleTakeRectificationExam={handleTakeRectificationExam}
                        />
                    );

                    AllLists.push(temList);
                });
                setSubjectManager(AllLists);
            } catch (error) {
                console.error(error);
            }
        };
        getQueryData();


        getQueryData().catch((data) => console.error(data));
        setInterval(() => {
            setLoader(false);
        }, 500);
    }, [reRender]);

    const handleTakeRectificationExam = (data) => {
        setShowTakeExam(true)
        setStudentData(data)
    }

    return (
        <>
            <Grid
                item
                container
                direction="column"
                spacing={1}
                className={classes.subContainer}
            >
                <Grid item>
                    {showTakeExam ?
                        <RectificationExamPage
                            setShowTakeExam={setShowTakeExam}
                            studentData={studentData}
                        />
                        : <>
                            {loader === false ? (
                                <ExamTabMakerAnalysis
                                    tabs={tabhead}
                                    data={subjectManager}
                                    subjectBased={true}
                                />
                            ) : (
                                <CircularProgress color="secondary" />
                            )}
                        </>}
                </Grid>
            </Grid>
        </>
    )
}

import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,

} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { url, makeReqBody } from "../Ui/store.js";

const useStyle = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "60em",
    },
    "& MuiDialog-paperWidthSm": {
      maxWidth: "100em",
    },
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.common.ltgrey,
  },

}));


const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  DialogHeader: {
    backgroundColor: theme.palette.common.ltgrey,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={classes.DialogHeader}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: theme.palette.common.tabFont }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ReScheduleRegularExam(props) {
  const classes = useStyle();
  const theme = useTheme();
  const [snack, setSnack] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [resubmitDate, setResubmitDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [cutOff, setCutOff] = useState(0);

  const handleReUploadSubmit = () => {
    if (props.examType !== 2 && fromDate === null) {
      setMessage("Please Select From Date.");
      setStatus("error")
      setSnack(true);
    }
    else if (props.examType !== 2 && toDate === null) {
      setMessage("Please Select To Date.");
      setStatus("error")
      setSnack(true);
    }
    else if (props.examType === 1 && cutOff === "" || props.examType === 1 && cutOff === 0) {
      setMessage("Please Enter Cut of Duration(Mins).");
      setStatus("error")
      setSnack(true);
      return;
    }
    else if (props.examType === 2 && resubmitDate === null) {
      setMessage("Please Select Reschedule Date.");
      setStatus("error")
      setSnack(true);
    } else if (props.examType !== 2 && Date.parse(fromDate) < Date.parse(new Date()) || props.examType === 2 && Date.parse(resubmitDate) < Date.parse(new Date())) {
      setMessage("Exam time cannot be less than system time.");
      setStatus("error");
      setSnack(true);
    } else {
      try {
        fetch(
          url,
          makeReqBody(window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? "SCHOOL_GET_SMART_QBT_SAVE_PUBLISHED_EXAM" : "SCHOOL_PUBLISHED_RESCHEDULE_REXAM", {
            action: "Reschedule",
            examDate: props.examType === 2 ? moment(resubmitDate).format("YYYY-MM-DD HH:mm:ss").toString() : '',
            examId: props.examId,
            cutOffDuration: props.examType === 1 ? cutOff : props.examType === 3 ? 2 : '',
            endDateTime: props.examType === 1 || props.examType === 3 ? moment(toDate).format("YYYY-MM-DD HH:mm:ss").toString() : '',
            startDateTime: props.examType === 1 || props.examType === 3 ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss").toString() : '',
            loginUserId: window.localStorage.userId,
            // Smart Exam Params------
            epId: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? props.reScheduleSmartExamData.epId : 0,
            examTermId: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? props.reScheduleSmartExamData.examTermId : 0,
            reschedule: true,
            sectionId: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? props.reScheduleSmartExamData.sectionIdc : 0,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            setMessage(data.respData[0].respMsg.message);
            data.respData[0].respMsg.status == "Failure"
              ? setStatus("error")
              : setStatus("success");
            setSnack(true);
            setToDate(null);
            setFromDate(null);
            setCutOff(0);
            props.successReScheduleExam();
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (data) {
        console.error(data);
      }

    }
  }
  const cutoffValidation =(e)=>{
    const value = e.target.value;

    if (value === "") {
      // Allow empty input for clearing the field
      setCutOff("");
    } else if (/^\d+$/.test(value)) {
      // Check if value is a non-negative integer
      setCutOff(value);
    } else if (value < 0) {
      // Handle negative values
      setStatus("Warning");
      setMessage("Negative value not allowed.");
      setSnack(true);
    } else {
      // Handle non-integer values
      setStatus("Warning");
      setMessage("Cutoff should be an integer.");
      setSnack(true);
    }
  }


  return (
    <React.Fragment>
      <Dialog
        open={props.openReScheduleExam}
        onClose={props.closeReScheduleExam}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={props.closeReScheduleExam}
        >
          Allow Reupload
        </DialogTitle>
        <DialogContent style={{ minWidth: "40em" }}>
          <Grid container direction="column" spacing={2}>
            <Grid item sm={12}>
              <Typography variant="h6" color="secondary">
                Are you sure you want to extend the upload time for the entire class?
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item sm={12} style={props.style}>
                <TextField
                  disabled
                  variant="outlined"
                  fullWidth
                  label="Subject Name"
                  value={props.subjectName}
                />
              </Grid>
              {(props.examType === 1 || props.examType === 3) && <>
                <Grid item sm={6}>
                  <DateTimePicker
                    label="From *"
                    disablePast
                    inputVariant="outlined"
                    ampm={false}
                    fullWidth
                    value={fromDate}
                    onChange={(mydate) => {
                      if (moment(mydate).format("YYYY-MM-DD HH:mm") === moment(toDate).format("YYYY-MM-DD HH:mm")) {
                        setStatus("Warning");
                        setMessage(
                          "Start Time should not be equal to End time."
                        );
                        setSnack(true);
                      } else if (moment(mydate).format("YYYY-MM-DD HH:mm") > moment(toDate).format("YYYY-MM-DD HH:mm")) {
                        setStatus("Warning");
                        setMessage(
                          "Start Time should not be greater than End time."
                        );
                        setSnack(true);
                      } else {
                        setFromDate(mydate);
                        props.examType === 3 && setToDate(mydate + props.totalTime * 60000)
                      }
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <DateTimePicker
                    label="To *"
                    inputVariant="outlined"
                    ampm={false}
                    disabled={props.examType === 3}
                    fullWidth
                    disablePast
                    value={toDate}
                    onChange={(mydate) => {
                      if (moment(mydate).format("YYYY-MM-DD HH:mm") === moment(fromDate).format("YYYY-MM-DD HH:mm")) {
                        setStatus("Warning");
                        setMessage(
                          "Start Time should not be equal to End time."
                        );
                        setSnack(true);
                      }
                      else if (
                        moment(mydate, "YYYY-MM-DD").diff(
                          moment(fromDate, "YYYY-MM-DD")
                        ) >= 0
                      ) {
                        setToDate(mydate)
                      } else {
                        setStatus("Warning");
                        setMessage(
                          "End time should be greater than the Start Time"
                        );
                        setSnack(true);
                      }
                    }}
                  />
                </Grid>
                {props.examType === 1 &&
                  <Grid item sm={12} style={props.style}>
                    <TextField
                      variant="outlined"
                      label="Cut-Off Duration(Mins)"
                      inputProps={{ type: "number", min: 0, step: 1 }}
                      value={cutOff}
                      fullWidth
                      onChange={cutoffValidation}
                    />
                  </Grid>}
              </>
              }

              {props.examType === 2 &&
                <Grid item sm={12}>
                  <DateTimePicker
                    label="Select Date"
                    disablePast
                    inputVariant="outlined"
                    ampm={false}
                    color="secondary"
                    style={{
                      color: theme.palette.common.tabFont,
                    }}
                    fullWidth
                    value={resubmitDate}
                    onChange={setResubmitDate}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={props.closeReScheduleExam}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ color: "white" }}
            color="secondary"
            onClick={handleReUploadSubmit}
          >
            {props.examType === 3 ? "Update Exam Time" :"Update Upload Date"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack}
        autoHideDuration={9000}
        onClose={() => setSnack(false)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnack(false)}
            >
              <CloseIcon
                style={{ color: theme.palette.common.tabFont }}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={status}>{message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}

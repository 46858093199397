import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import TabMaker from "./TabMaker";
import { makeStyles } from "@material-ui/core/styles";
import { url, makeReqBody } from "./store.js";
import ChapterDetailed from "../DetailedAnalysis/ChapterDetailed";
import SubjectWiseDetailed from "../DetailedAnalysis/SubjectWiseDetailed";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
}));


export default function DetailedAnalysis() {
  const classes = useStyle();
  const [subjectManager, setSubjectManager] = useState([]);
  const [tabhead, setTabHead] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    let AllList = [];
    const getQueryData = async () => {
      try {
        await fetch(url,
          makeReqBody("SCHOOL_GET_EXAM_TERMS_FOR_BRANCH", window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? {
            id: window.localStorage.branchId,
            sectionId: window.localStorage.sectionId
          } : {
            id: window.localStorage.branchId,
            standard: window.localStorage.className,
          })
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            let tabheadings = [];

            if (data.respData[0].respMsg.result.length > 0) {
              try {
                tabheadings = data.respData[0].respMsg.result.map(
                  (a) => a.name
                );
                data.respData[0].respMsg.result &&
                  data.respData[0].respMsg.result.map((item, index1) => {
                    let component = (
                      window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ?
                        <SubjectWiseDetailed
                          key={index1}
                          index={index1}
                          examTermId={item.examTermId}
                        />
                        : <ChapterDetailed
                          key={index1}
                          examTermId={item.examTermId}
                        />
                    );

                    AllList.push(component);
                  });

                setSubjectManager(AllList);
                setTabHead(tabheadings);
              } catch (error) {
                console.error(error);
                setTabHead(["Network Error"]);
              }
            } else {
              setTabHead(["Not Assigned to a group"]);
            }
          })
          .catch((data) => {
            console.error(data);
          });
      } catch (error) {
        console.error(error);
      }
    };

    getQueryData().catch((data) => console.error(data));
    setInterval(() => {
      setLoader(false);
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <TabMaker
              tabs={tabhead}
              data={subjectManager}
            />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import ExamWorkTabMaker from "../Exam/ExamWorkTabMaker";
import TableMaker from "./TableMaker";
import { makeStyles } from "@material-ui/core/styles";
import { url } from "./store.js";
import moment from "moment";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  subContainer: {
    ...theme.typography.subContainer,
  },
  table: {
    backgroundColor: "transparent",
    minwidth: "30em",
  },
  tableHead: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
  },
}));

const MyDataMaker = (props) => {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const history = useHistory()

  useEffect(() => {
    setLoader(true);
    try {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_EXAM_FOR_STUDENT",
              busiParams: {
                examTermId: props.examTermId,
                studentInstanceId: window.localStorage.studentInstanceId,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let row = [];
          data.respData[0].respMsg.result.length > 0
            ? data.respData[0].respMsg.result.map((item, index) => {
              window.localStorage.roleId === "5"
                ? row.push([
                  {
                    title: item.subjectName,
                    type: "text",
                    examTxnId: item.eExamTxnId,
                  },
                  {
                    startDate: item.startDateTime,
                    endDate: item.endDateTime,
                    title: item.examType !== 2 ? props.dateFromatFunc(
                      item.startDateTime,
                      item.endDateTime
                    ) : item.examType === 2 ? moment(item.examDate)
                      .format("MMM DD, YYYY, HH:mm")
                      .toString() : '',
                    mytime: item.examType === 2 ? moment(item.startDateTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ) : item.examType === 2 ? moment(item.examDate).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ) : '',
                    type: item.examType !== 2 ? "schedule" : "text",
                  },
                  {
                    title:
                      item.examType !== 2 ? item.resubmitEndDate &&
                        moment(item.resubmitEndDate).format(
                          "MMM DD YYYY, HH:mm"
                        ) : '',
                    date:
                      item.examType !== 2 ? item.resubmitEndDate &&
                        moment(item.resubmitEndDate).format(
                          "YYYY-MM-DD HH:mm:ss"
                        ) : '',
                    type: "text",
                  },
                ])
                : row.push([
                  {
                    title: item.subjectName,
                    type: "text",
                    examTxnId: item.eExamTxnId,
                  },
                  {
                    startDate: item.startDateTime,
                    endDate: item.endDateTime,
                    title: item.examType !== 2 ? props.dateFromatFunc(
                      item.startDateTime,
                      item.endDateTime
                    ) : item.examType === 2 ? moment(item.examDate).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ) : '',
                    mytime: item.examType !== 2 ? moment(item.startDateTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ) : item.examType === 2 ? moment(item.examDate).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ) : '',
                    type: item.examType !== 2 ? "schedule" : "text",
                  },
                  {
                    title:
                      item.examType !== 2 ? item.resubmitEndDate &&
                        moment(item.resubmitEndDate).format(
                          "MMM DD YYYY, HH:mm"
                        ) : '',
                    date:
                      item.examType !== 2 ? item.resubmitEndDate &&
                        moment(item.resubmitEndDate).format(
                          "YYYY-MM-DD HH:mm:ss"
                        ) : '',
                    type: "text",
                  },
                  {
                    title: item.solutionFileName,
                    type: "uploadExam",
                    timestart: item.startDateTime,
                    timeend: item.endDateTime,
                    cutoff: item.cutOffDateTime,
                    cutoffduration: item.cutOffDuration,
                    examStatus: item.examStatus,
                    fullDetails: item,
                  },
                ]);
            })
            : row.push([
              {
                title: "No Data Available",
                type: "text",
              },
              {
                title: " ",
                type: "text",
              },
              {
                title: " ",
                type: "text",
              },
              {
                title: " ",
                type: "text",
              },
            ]);

          setRows(row);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        })

        .catch((data) => {
          console.error(data);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    }
  }, []);


  const headers = window.localStorage.roleId == "5"
    ? ["Subject", "Schedule", "Resubmit End Date"]
    : ["Subject", "Schedule", "Resubmit End Date", "Solution"];

  return (
    <React.Fragment>
      {loader === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <TableMaker
          columns={headers}
          rows={rows}
          toReload={props.toReload}
          setToReLoad={props.setToReLoad}
        />
      )}
    </React.Fragment>
  );
};

export default function ExamWorks() {
  const classes = useStyle();
  const [rowsForTab, setRowsForTab] = React.useState([]);
  // const preMidTerm = <TableMaker columns={headers} rows={rowsForTab} />;
  const [loader, setLoader] = useState(true);
  // const myTableData = [preMidTerm];

  const [tabheadings, setTabHeadings] = React.useState([]);
  const [eExam, setEExam] = React.useState([]);

  const [toReload, setToReLoad] = useState(false);




  const month = {
    "01": "JAN",
    "02": "FEB",
    "03": "MAR",
    "04": "APR",
    "05": "MAY",
    "06": "JUN",
    "07": "JUL",
    "08": "AUG",
    "09": "SEP",
    10: "OCT",
    11: "NOV",
    12: "DEC",
  };

  const dateFromatFunc = (startDateTime, endDateTime) => {
    console.log("inside date func");
    //May 08, 2020, 9:30 am to 11:30 am
    //2020-09-08 12:55:13
    const sd = startDateTime.split(" ")[0];
    const st = startDateTime.split(" ")[1];
    const et = endDateTime.split(" ")[1];

    const sdSplit = sd.split("-");
    const sy = sdSplit[0];
    const sm = month[sdSplit[1]];
    const sday = sdSplit[2];

    const stSplit = st.split(":");
    const sh = stSplit[0];
    const smin = stSplit[1];

    let startAmPm = "am";
    if (parseInt(sh) >= 12) {
      startAmPm = "pm";
    }

    const etSplit = et.split(":");
    const eh = etSplit[0];
    const emin = etSplit[1];

    let endAmPm = "am";
    if (parseInt(sh) >= 12) {
      endAmPm = "pm";
    }

    let duration =
      sm +
      " " +
      sday +
      "," +
      " " +
      sy +
      "," +
      " " +
      sh +
      ":" +
      smin +
      " " +
      startAmPm +
      " " +
      "to" +
      " " +
      eh +
      ":" +
      emin +
      " " +
      endAmPm;

    return duration;
    // console.log("the dareturn duration" + date);
  };

  useEffect(() => {
    setLoader(true);
    const getData = async () => {
      let termData = [];
      let subjectName = [];
      await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          requestList: [
            {
              isEncrypt: false,
              transactionId: "0000001438241244",
              busiCode: "SCHOOL_GET_EXAM_TERMS_FOR_BRANCH",
              busiParams: {
                id: window.localStorage.branchId,
                standard: window.localStorage.className,
              },
            },
          ],
          pubInfo: {
            appId: "appId",
            sessionId: window.localStorage.sessionId,
            version: "21",
            timestamp: "20180314175419",
            lang: "en_US",
            userId: "1000016614",
            serviceId: "7021150585",
            circleId: "MU",
            ncsroute: "WE015",
            customerId: "1000016614",
            osType: "windows",
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          data.respData[0].respMsg.result.map((item, index) => {
            subjectName.push(item.name);
            termData.push(item);
          });

          setTabHeadings(subjectName);
        })
        .catch((data) => {
          console.log("sorry something went wrong");
        });
      try {
        let AllLists = [];

        termData.map((item, index) => {
          let temList = (
            <MyDataMaker
              key={index}
              toReload={toReload}
              setToReLoad={setToReLoad}
              examTermId={item.examTermId}
              dateFromatFunc={dateFromatFunc}
            />
          );

          AllLists.push(temList);
        });

        setEExam(AllLists);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
    setInterval(() => {
      setLoader(false);
    }, 500);
  }, [toReload]);

  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="column"
        spacing={1}
        className={classes.subContainer}
      >
        <Grid item>
          {loader === false ? (
            <ExamWorkTabMaker tabs={tabheadings} data={eExam} subjectBased={true} />
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    CircularProgress,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    MenuItem,
    AppBar,
    Toolbar,
    Switch,

} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";

import { url, makeReqBody } from "../Ui/store.js";

const useStyle = makeStyles((theme) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "60em",
        },
        "& MuiDialog-paperWidthSm": {
            maxWidth: "100em",
        },
    },
    appBar: {
        position: "sticky",
        backgroundColor: theme.palette.common.ltgrey,
    },

}));


const styles = (theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.common.black,
    },
    DialogHeader: {
        backgroundColor: theme.palette.common.ltgrey,
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    const theme = useTheme();
    return (
        <MuiDialogTitle
            disableTypography
            {...other}
            className={classes.DialogHeader}
        >
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon style={{ color: theme.palette.common.tabFont }} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PublishRegularExam(props) {
    const classes = useStyle();
    const theme = useTheme();
    const [snack, setSnack] = useState(false);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState("");
    const [myLoader, setmyLoader] = useState(false);

    const handlePublishExamRegular = () => {
        setmyLoader(true)
        try {
            fetch(
                url,
                makeReqBody(window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? "SCHOOL_GET_SMART_QBT_SAVE_PUBLISHED_EXAM" : "SCHOOL_PUBLISHED_RESCHEDULE_REXAM", {
                    action: "Published",
                    examId: props.examId,
                    loginUserId: window.localStorage.userId,
                    published: true,
                    // Smart Exam Params------
                    epId: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? props.publishRegularData.epId : 0,
                    examTermId: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? props.publishRegularData.examTermId : 0,
                    reschedule: false,
                    sectionId: window.localStorage.boardId > 6 && window.localStorage.boardId < 10 ? props.publishRegularData.sectionId : 0,
                })
            )
                .then((response) => response.json())
                .then((data) => {
                    setmyLoader(false)
                    setMessage(data.respData[0].respMsg.message);
                    data.respData[0].respMsg.status == "Failure"
                        ? setStatus("error")
                        : setStatus("success");
                    setSnack(true);
                    props.closePublishExamRegularSuccess();
                })
                .catch((data) => {
                    console.error(data);
                });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.publishExamRegular}
                onClose={props.closePublishExamRegular}
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={props.closePublishExamRegular}
                >
                    Confirm Exam Publishing
                </DialogTitle>
                <DialogContent style={{ minWidth: "40em" }}>
                    <Typography variant="body1" color="initial">
                        Are you sure you want to publish the Exam for this class ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={props.closePublishExamRegular}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ color: "white" }}
                        onClick={handlePublishExamRegular}
                        disabled={myLoader}
                    >
                        {myLoader ? <CircularProgress /> : "Publish"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snack}
                autoHideDuration={9000}
                onClose={() => setSnack(false)}
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setSnack(false)}
                        >
                            <CloseIcon
                                style={{ color: theme.palette.common.tabFont }}
                                fontSize="small"
                            />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Alert severity={status}>{message}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}
